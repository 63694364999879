import React, { useState, useRef, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { createCompanyJobDescription } from "../../graphql/mutations";
import Header from "../header/Header";
import { useNavigate } from "react-router-dom";
import { listCompanyJobDescriptions } from "../../graphql/queries";

const JobDetails = () => {
  const [jobTitle, setJobTitle] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedExperience, setSelectedExperience] = useState("");
  const [skill, setSkill] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [compensation, setCompensation] = useState("");
  const [benefits, setBenefits] = useState("");
  const navigate = useNavigate();
  const CompanyEmailID = localStorage.getItem("CompanyEmailID");
  const [workMode, setWorkMode] = useState("");

  const compensationRef = useRef(null);
  const [companyjobcount, setcompanyjobcount] = useState(false);

  // fetching companyjodescription count

  useEffect(() => {
    const fetchJobCount = async () => {
      const storedCompanyID = localStorage.getItem("CompanyProfileID");

      try {
        let nextToken = null;
        let jobCount = 0;

        do {
          const jobListResponse = await API.graphql(
            graphqlOperation(listCompanyJobDescriptions, {
              filter: { companyProfileID: { eq: storedCompanyID } },
              limit: 100,
              nextToken: nextToken,
            })
          );

          const jobDescriptions =
            jobListResponse.data.listCompanyJobDescriptions.items;
          jobCount += jobDescriptions.length;

          nextToken = jobListResponse.data.listCompanyJobDescriptions.nextToken;
        } while (nextToken);

        // Set company count
        if (jobCount > 0) {
          // You can set company job count here
          setcompanyjobcount(true);
        } else {
          console.log("No job descriptions found for the company.");
          setcompanyjobcount(false);
        }
      } catch (error) {
        console.error("Error fetching job count:", error);
        // Handle error appropriately
      }
    };

    fetchJobCount();
  }, []);

  console.log(
    "$$$$$$$$$$$$$$$$$$$$$$$$$@@@@@@@@@@@@@@@@@@@@@@@@@@",
    companyjobcount
  );

  const handleCompensationClick = () => {
    compensationRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if all mandatory fields are filled out
    if (
      !jobTitle ||
      !selectedCategory ||
      !selectedExperience ||
      !skill ||
      !jobDescription ||
      !compensation ||
      !benefits ||
      !workMode // Check if work mode is selected
    ) {
      alert("Please fill out all mandatory fields.");
      return;
    }

    try {
      const response = await API.graphql({
        query: createCompanyJobDescription,
        variables: {
          input: {
            jobTitle,
            selectedCategory,
            selectedExperience,
            skill,
            jobDescription,
            compensation,
            benefits,
            workMode, // Include work mode in the variables
            companyProfileID: localStorage.getItem("CompanyProfileID"),
            jobIDNo: 45, // Pass the actual value of jobIDNo variable here
          },
        },
      });

      if (response.errors && response.errors.length > 0) {
        console.error("Error submitting job details:", response.errors);
        // Show error message or handle the errors accordingly
      } else {
        console.log("Job details submitted successfully:", response);
        // Reset form
        setJobTitle("");
        setSelectedCategory("");
        setSelectedExperience("");
        setSkill("");
        setJobDescription("");
        setCompensation("");
        setBenefits("");
        setWorkMode(""); // Reset work mode
      }
      navigate("/JobDetailsList");
    } catch (error) {
      console.error("Error submitting job details:", error);
      // Show error message
    }
  };

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const handleExperienceSelect = (experience) => {
    setSelectedExperience(experience);
  };

 

  const renderCategoryButtons = () => {
    const categories = [
      "Full-time",
      "Part-time",
      "Contract",
      "Temporary",
      "Internship",
    ];
    return categories.map((category, index) => (
      <button
        key={index}
        style={{
          padding: "5px 15px",
          backgroundColor:
            selectedCategory === category ? "#9900ff" : "#f3f3f3",
          borderRadius: "15px",
          cursor: "pointer",
          transition: "background-color 0.3s",
          marginRight: "5px",
          marginBottom: "5px",
          color: selectedCategory === category ? "#fff" : "#000",
          border: "1px solid #9900ff",
        }}
        onClick={() => handleCategorySelect(category)}
      >
        {category}
      </button>
    ));
  };

  const renderExperienceButtons = () => {

    // original code start

    // const experienceLevels = [
    //   { label: "Fresher\nJust out of college", value: "Fresher" },
    //   { label: "Entry Level\n1-3 years", value: "Entry Level" },
    //   { label: "Mid Level\n3-5 years", value: "Mid Level" },
    //   { label: "Senior Level\n5+ years", value: "Senior Level" },
    // ];
    
    // original code end

    const experienceLevels = [
      { label: "Fresher\nJust out of college", value: "Fresher" },
      { label: "Entry Level\n1-3 years", value: "Entry Level 1-3 years" },
      { label: "Mid Level\n3-5 years", value: "Mid Level 3-5 years" },
      { label: "Senior Level\n5+ years", value: "Senior Level 5+ years" },
    ];

    return experienceLevels.map((experience, index) => (
      <button
        key={index}
        style={{
          backgroundColor:
            selectedExperience === experience.value ? "#9900ff" : "#1b1935",
          border: "1px solid #9900ff",
          borderRadius: "5px",
          padding: "15px 10px",
          textAlign: "center",
          fontSize: "14px",
          cursor: "pointer",
          flexGrow: "1",
          margin: "0 5px",
          transition: "border-color 0.3s ease",
        }}
        onClick={() => handleExperienceSelect(experience.value)}
      >
        {experience.label}
      </button>
    ));
  };


  const handleWorkModeSelect = (mode) => {
    setWorkMode(mode);
  };

  const renderWorkModeOptions = () => {
    const workModes = ["Work from home", "Remote", "Work from office"];

    return workModes.map((mode, index) => (
      <button
        key={index}
        style={{
          padding: "5px 15px",
          backgroundColor: workMode === mode ? "#9900ff" : "#f3f3f3",
          borderRadius: "15px",
          cursor: "pointer",
          transition: "background-color 0.3s",
          marginRight: "5px",
          marginBottom: "5px",
          color: workMode === mode ? "#fff" : "#000",
          border: "1px solid #9900ff",
        }}
        onClick={() => handleWorkModeSelect(mode)}
      >
        {mode}
      </button>
    ));
  };

  return (
    <div>
      <Header />
      {/* 
      {companyjobcount === true &&
      CompanyEmailID !== "hr@varahitechnologies.com" ? (
        <div className="items-center text-center mt-14">
          <h1>you left your point</h1>
          <button className="border-1 hover:bg-[#9900FF] text-white font-bold py-2 px-4 rounded capitalize">
            Buy Premium
          </button>
        </div>
      ) : ( */}
      <div
        style={{
          flexGrow: "1",
          padding: "20px",
          border: "2px solid #9900ff",
          borderRadius: "6px",
        }}
        className="mx-44 mt-16 "
      >
        <h1
          style={{
            color: "#ffffff",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Create Your Course
        </h1>

        <div style={{ marginBottom: "15px" }}>
          <label
            htmlFor="job-title"
            style={{
              display: "block",
              color: "#ffffff",
              marginBottom: "8px",
            }}
          >
            Course Title
          </label>
          <input
            type="text"
            id="job-title"
            placeholder="Enter job title"
            value={jobTitle}
            onChange={(e) => setJobTitle(e.target.value)}
            style={{
              width: "97%",
              padding: "10px",
              marginBottom: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              backgroundColor: "transparent",
              color: "#ffffff",
            }}
          />
        </div>
        <div style={{ marginBottom: "20px" }}>
          <label
            htmlFor="job-search"
            style={{
              display: "block",
              color: "#ffffff",
              marginBottom: "8px",
            }}
          >
            Course Category
          </label>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            {renderCategoryButtons()}
          </div>
        </div>

        
        <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "10px",
              color: "#ffffff",
            }}
          >
            Work Mode
          </label>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              marginBottom: "20px",
            }}
          >
            {renderWorkModeOptions()}
          </div>
        </div>



        <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "10px",
              color: "#ffffff",
            }}
          >
            Experience Level
          </label>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
              boxSizing: "border-box",
              maxWidth: "600px",
              margin: "auto",
            }}
          >
            {renderExperienceButtons()}
          </div>
        </div>

        <div style={{ marginBottom: "15px" }}>
          <label
            htmlFor="skill"
            style={{
              display: "block",
              color: "#ffffff",
              marginBottom: "8px",
            }}
          >
            Skill
          </label>
          <input
            type="text"
            id="skill"
            placeholder="Enter skills separated by comma"
            value={skill}
            onChange={(e) => setSkill(e.target.value)}
            style={{
              width: "97%",
              padding: "10px",
              marginBottom: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              backgroundColor: "transparent",
              color: "#ffffff",
            }}
          />
        </div>

        <div style={{ marginBottom: "15px" }}>
          <label
            htmlFor="job-description"
            style={{
              display: "block",
              color: "#ffffff",
              marginBottom: "8px",
            }}
          >
            Course Description
          </label>
          <textarea
            id="job-description"
            placeholder="Enter job description"
            rows="4"
            value={jobDescription}
            onChange={(e) => setJobDescription(e.target.value)}
            style={{
              width: "97%",
              padding: "10px",
              marginBottom: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              backgroundColor: "transparent",
              color: "#ffffff",
            }}
          ></textarea>
        </div>

        {/* <button
        onClick={handleCompensationClick}
        style={{
          padding: "10px 15px",
          backgroundColor: "#9900ff",
          color: "#ffffff",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
          marginBottom: "20px",
        }}
      >
        Compensation
      </button> */}

        <div ref={compensationRef}>
          <div style={{ marginBottom: "15px" }}>
            <label
              htmlFor="compensation"
              style={{
                display: "block",
                color: "#ffffff",
                marginBottom: "8px",
              }}
            >
              Fees
            </label>
            <input
              type="text"
              id="compensation"
              placeholder="eg : 6LPA-12LPA"
              value={compensation}
              onChange={(e) => setCompensation(e.target.value)}
              style={{
                width: "97%",
                padding: "10px",
                marginBottom: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                backgroundColor: "transparent",
                color: "#ffffff",
              }}
            />
          </div>

          <div style={{ marginBottom: "15px" }}>
            <label
              htmlFor="benefits"
              style={{
                display: "block",
                color: "#ffffff",
                marginBottom: "8px",
              }}
            >
              Benefits
            </label>
            <textarea
              id="benefits"
              placeholder="Enter benefits details"
              rows="4"
              value={benefits}
              onChange={(e) => setBenefits(e.target.value)}
              style={{
                width: "97%",
                padding: "10px",
                marginBottom: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                backgroundColor: "transparent",
                color: "#ffffff",
              }}
            ></textarea>
          </div>

          <div style={{ textAlign: "right" }}>
            <button
              type="button"
              onClick={() => {
                // Handle cancel action
                navigate("/JobDetailsList"); // Navigate to JobDetailsList
              }}
              style={{
                padding: "10px 15px",
                marginLeft: "10px",
                backgroundColor: "#d9534f",
                color: "#ffffff",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              onClick={handleSubmit}
              style={{
                padding: "10px 15px",
                marginLeft: "10px",
                backgroundColor: "#5cb85c",
                color: "#ffffff",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};
export default JobDetails;
