import React, { useState } from 'react'

const UpdateCandidate = () => {

  const [email, setEmail] = useState('');
  const [videoFile, setVideoFile] = useState(null);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setVideoFile(file);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Assuming you want to upload the video file somewhere, you can use FormData API
    const formData = new FormData();
    formData.append('email', email);
    formData.append('video', videoFile);

    // Example: Send formData to backend or do further processing
    console.log('Form Data:', formData);
    console.log('Form Data:', email);
    console.log('Form Data:', videoFile);

    // Reset form fields after submission
    setEmail('');
    setVideoFile(null);
  };


  return (
    <div className="max-w-sm mx-auto mt-10 p-6 border-white rounded-lg shadow-md">
      <h2 className="text-xl font-semibold mb-6 text-center">Update Profile</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 ">
            Email Address
          </label>
          <input
            type="email"
            id="email"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm bg-transparent"
            placeholder="Enter your email address"
            value={email}
            onChange={handleEmailChange}
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="video" className="block text-sm font-medium text-gray-700">
            Upload Video
          </label>
          <input
            type="file"
            id="video"
            accept="video/mp4,video/x-m4v,video/*"
            onChange={handleFileChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full bg-indigo-500 text-white py-2 px-4 mt-4 rounded-md hover:bg-indigo-600 transition duration-300"
        >
          Update Profile
        </button>
      </form>
    </div>
  )
}

export default UpdateCandidate
