/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCandidateProactringData = /* GraphQL */ `
  mutation CreateCandidateProactringData(
    $input: CreateCandidateProactringDataInput!
    $condition: ModelCandidateProactringDataConditionInput
  ) {
    createCandidateProactringData(input: $input, condition: $condition) {
      id
      candidateId
      candidateEmail
      imageResult1
      pdfResult
      textResult
      imageResult2
      imageResult3
      imageResult4
      imageResult5
      videoResult1
      videoResult2
      videoResult3
      videoResult4
      videoResult5
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCandidateProactringData = /* GraphQL */ `
  mutation UpdateCandidateProactringData(
    $input: UpdateCandidateProactringDataInput!
    $condition: ModelCandidateProactringDataConditionInput
  ) {
    updateCandidateProactringData(input: $input, condition: $condition) {
      id
      candidateId
      candidateEmail
      imageResult1
      pdfResult
      textResult
      imageResult2
      imageResult3
      imageResult4
      imageResult5
      videoResult1
      videoResult2
      videoResult3
      videoResult4
      videoResult5
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCandidateProactringData = /* GraphQL */ `
  mutation DeleteCandidateProactringData(
    $input: DeleteCandidateProactringDataInput!
    $condition: ModelCandidateProactringDataConditionInput
  ) {
    deleteCandidateProactringData(input: $input, condition: $condition) {
      id
      candidateId
      candidateEmail
      imageResult1
      pdfResult
      textResult
      imageResult2
      imageResult3
      imageResult4
      imageResult5
      videoResult1
      videoResult2
      videoResult3
      videoResult4
      videoResult5
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAdminAddCandidateContext = /* GraphQL */ `
  mutation CreateAdminAddCandidateContext(
    $input: CreateAdminAddCandidateContextInput!
    $condition: ModelAdminAddCandidateContextConditionInput
  ) {
    createAdminAddCandidateContext(input: $input, condition: $condition) {
      id
      candidateEmail
      candidateName
      candidateVideo
      candidateContextTextFile
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAdminAddCandidateContext = /* GraphQL */ `
  mutation UpdateAdminAddCandidateContext(
    $input: UpdateAdminAddCandidateContextInput!
    $condition: ModelAdminAddCandidateContextConditionInput
  ) {
    updateAdminAddCandidateContext(input: $input, condition: $condition) {
      id
      candidateEmail
      candidateName
      candidateVideo
      candidateContextTextFile
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAdminAddCandidateContext = /* GraphQL */ `
  mutation DeleteAdminAddCandidateContext(
    $input: DeleteAdminAddCandidateContextInput!
    $condition: ModelAdminAddCandidateContextConditionInput
  ) {
    deleteAdminAddCandidateContext(input: $input, condition: $condition) {
      id
      candidateEmail
      candidateName
      candidateVideo
      candidateContextTextFile
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCandidateProfile = /* GraphQL */ `
  mutation CreateCandidateProfile(
    $input: CreateCandidateProfileInput!
    $condition: ModelCandidateProfileConditionInput
  ) {
    createCandidateProfile(input: $input, condition: $condition) {
      id
      user
      userID
      fullName
      gender
      mobileNumber
      description
      matchedPercentage
      workStatus
      currentCity
      educationDetails
      keySkills
      workPreference
      preferredSalary
      photoURL
      sopURL
      isPublic
      t_c
      email
      roleApplyFor
      address
      interviewScheduleCount
      interviewSlots {
        nextToken
        __typename
      }
      s3objects {
        nextToken
        __typename
      }
      private_Candidate
      private_Candidate_Release
      workMode
      workCategory
      linkedinURL
      githubURL
      coverPhoto
      universityApplyFor
      courseApplyingFor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCandidateProfile = /* GraphQL */ `
  mutation UpdateCandidateProfile(
    $input: UpdateCandidateProfileInput!
    $condition: ModelCandidateProfileConditionInput
  ) {
    updateCandidateProfile(input: $input, condition: $condition) {
      id
      user
      userID
      fullName
      gender
      mobileNumber
      description
      matchedPercentage
      workStatus
      currentCity
      educationDetails
      keySkills
      workPreference
      preferredSalary
      photoURL
      sopURL
      isPublic
      t_c
      email
      roleApplyFor
      address
      interviewScheduleCount
      interviewSlots {
        nextToken
        __typename
      }
      s3objects {
        nextToken
        __typename
      }
      private_Candidate
      private_Candidate_Release
      workMode
      workCategory
      linkedinURL
      githubURL
      coverPhoto
      universityApplyFor
      courseApplyingFor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCandidateProfile = /* GraphQL */ `
  mutation DeleteCandidateProfile(
    $input: DeleteCandidateProfileInput!
    $condition: ModelCandidateProfileConditionInput
  ) {
    deleteCandidateProfile(input: $input, condition: $condition) {
      id
      user
      userID
      fullName
      gender
      mobileNumber
      description
      matchedPercentage
      workStatus
      currentCity
      educationDetails
      keySkills
      workPreference
      preferredSalary
      photoURL
      sopURL
      isPublic
      t_c
      email
      roleApplyFor
      address
      interviewScheduleCount
      interviewSlots {
        nextToken
        __typename
      }
      s3objects {
        nextToken
        __typename
      }
      private_Candidate
      private_Candidate_Release
      workMode
      workCategory
      linkedinURL
      githubURL
      coverPhoto
      universityApplyFor
      courseApplyingFor
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInterviewSlot = /* GraphQL */ `
  mutation CreateInterviewSlot(
    $input: CreateInterviewSlotInput!
    $condition: ModelInterviewSlotConditionInput
  ) {
    createInterviewSlot(input: $input, condition: $condition) {
      id
      candidateProfile {
        id
        user
        userID
        fullName
        gender
        mobileNumber
        description
        matchedPercentage
        workStatus
        currentCity
        educationDetails
        keySkills
        workPreference
        preferredSalary
        photoURL
        sopURL
        isPublic
        t_c
        email
        roleApplyFor
        address
        interviewScheduleCount
        private_Candidate
        private_Candidate_Release
        workMode
        workCategory
        linkedinURL
        githubURL
        coverPhoto
        universityApplyFor
        courseApplyingFor
        createdAt
        updatedAt
        __typename
      }
      candidateProfileID
      date
      startTime
      endTime
      status
      meetingLinkURL
      meetingConfig
      candidateAttendance
      botAttendance
      interviewEvaluation {
        id
        interviewSlotID
        roundNumber
        reappearRoundNumber
        feedbackForAI
        transcript
        evaluation
        videoURL
        candidateEmail
        createdAt
        updatedAt
        interviewEvaluationInterviewSlotId
        __typename
      }
      candidateEmail
      createdAt
      updatedAt
      candidateProfileInterviewSlotsId
      interviewSlotInterviewEvaluationId
      __typename
    }
  }
`;
export const updateInterviewSlot = /* GraphQL */ `
  mutation UpdateInterviewSlot(
    $input: UpdateInterviewSlotInput!
    $condition: ModelInterviewSlotConditionInput
  ) {
    updateInterviewSlot(input: $input, condition: $condition) {
      id
      candidateProfile {
        id
        user
        userID
        fullName
        gender
        mobileNumber
        description
        matchedPercentage
        workStatus
        currentCity
        educationDetails
        keySkills
        workPreference
        preferredSalary
        photoURL
        sopURL
        isPublic
        t_c
        email
        roleApplyFor
        address
        interviewScheduleCount
        private_Candidate
        private_Candidate_Release
        workMode
        workCategory
        linkedinURL
        githubURL
        coverPhoto
        universityApplyFor
        courseApplyingFor
        createdAt
        updatedAt
        __typename
      }
      candidateProfileID
      date
      startTime
      endTime
      status
      meetingLinkURL
      meetingConfig
      candidateAttendance
      botAttendance
      interviewEvaluation {
        id
        interviewSlotID
        roundNumber
        reappearRoundNumber
        feedbackForAI
        transcript
        evaluation
        videoURL
        candidateEmail
        createdAt
        updatedAt
        interviewEvaluationInterviewSlotId
        __typename
      }
      candidateEmail
      createdAt
      updatedAt
      candidateProfileInterviewSlotsId
      interviewSlotInterviewEvaluationId
      __typename
    }
  }
`;
export const deleteInterviewSlot = /* GraphQL */ `
  mutation DeleteInterviewSlot(
    $input: DeleteInterviewSlotInput!
    $condition: ModelInterviewSlotConditionInput
  ) {
    deleteInterviewSlot(input: $input, condition: $condition) {
      id
      candidateProfile {
        id
        user
        userID
        fullName
        gender
        mobileNumber
        description
        matchedPercentage
        workStatus
        currentCity
        educationDetails
        keySkills
        workPreference
        preferredSalary
        photoURL
        sopURL
        isPublic
        t_c
        email
        roleApplyFor
        address
        interviewScheduleCount
        private_Candidate
        private_Candidate_Release
        workMode
        workCategory
        linkedinURL
        githubURL
        coverPhoto
        universityApplyFor
        courseApplyingFor
        createdAt
        updatedAt
        __typename
      }
      candidateProfileID
      date
      startTime
      endTime
      status
      meetingLinkURL
      meetingConfig
      candidateAttendance
      botAttendance
      interviewEvaluation {
        id
        interviewSlotID
        roundNumber
        reappearRoundNumber
        feedbackForAI
        transcript
        evaluation
        videoURL
        candidateEmail
        createdAt
        updatedAt
        interviewEvaluationInterviewSlotId
        __typename
      }
      candidateEmail
      createdAt
      updatedAt
      candidateProfileInterviewSlotsId
      interviewSlotInterviewEvaluationId
      __typename
    }
  }
`;
export const createInterviewEvaluation = /* GraphQL */ `
  mutation CreateInterviewEvaluation(
    $input: CreateInterviewEvaluationInput!
    $condition: ModelInterviewEvaluationConditionInput
  ) {
    createInterviewEvaluation(input: $input, condition: $condition) {
      id
      interviewSlot {
        id
        candidateProfileID
        date
        startTime
        endTime
        status
        meetingLinkURL
        meetingConfig
        candidateAttendance
        botAttendance
        candidateEmail
        createdAt
        updatedAt
        candidateProfileInterviewSlotsId
        interviewSlotInterviewEvaluationId
        __typename
      }
      interviewSlotID
      roundNumber
      reappearRoundNumber
      feedbackForAI
      transcript
      evaluation
      videoURL
      candidateEmail
      createdAt
      updatedAt
      interviewEvaluationInterviewSlotId
      __typename
    }
  }
`;
export const updateInterviewEvaluation = /* GraphQL */ `
  mutation UpdateInterviewEvaluation(
    $input: UpdateInterviewEvaluationInput!
    $condition: ModelInterviewEvaluationConditionInput
  ) {
    updateInterviewEvaluation(input: $input, condition: $condition) {
      id
      interviewSlot {
        id
        candidateProfileID
        date
        startTime
        endTime
        status
        meetingLinkURL
        meetingConfig
        candidateAttendance
        botAttendance
        candidateEmail
        createdAt
        updatedAt
        candidateProfileInterviewSlotsId
        interviewSlotInterviewEvaluationId
        __typename
      }
      interviewSlotID
      roundNumber
      reappearRoundNumber
      feedbackForAI
      transcript
      evaluation
      videoURL
      candidateEmail
      createdAt
      updatedAt
      interviewEvaluationInterviewSlotId
      __typename
    }
  }
`;
export const deleteInterviewEvaluation = /* GraphQL */ `
  mutation DeleteInterviewEvaluation(
    $input: DeleteInterviewEvaluationInput!
    $condition: ModelInterviewEvaluationConditionInput
  ) {
    deleteInterviewEvaluation(input: $input, condition: $condition) {
      id
      interviewSlot {
        id
        candidateProfileID
        date
        startTime
        endTime
        status
        meetingLinkURL
        meetingConfig
        candidateAttendance
        botAttendance
        candidateEmail
        createdAt
        updatedAt
        candidateProfileInterviewSlotsId
        interviewSlotInterviewEvaluationId
        __typename
      }
      interviewSlotID
      roundNumber
      reappearRoundNumber
      feedbackForAI
      transcript
      evaluation
      videoURL
      candidateEmail
      createdAt
      updatedAt
      interviewEvaluationInterviewSlotId
      __typename
    }
  }
`;
export const createS3object = /* GraphQL */ `
  mutation CreateS3object(
    $input: CreateS3objectInput!
    $condition: ModelS3objectConditionInput
  ) {
    createS3object(input: $input, condition: $condition) {
      id
      objectId
      type
      candidateProfile {
        id
        user
        userID
        fullName
        gender
        mobileNumber
        description
        matchedPercentage
        workStatus
        currentCity
        educationDetails
        keySkills
        workPreference
        preferredSalary
        photoURL
        sopURL
        isPublic
        t_c
        email
        roleApplyFor
        address
        interviewScheduleCount
        private_Candidate
        private_Candidate_Release
        workMode
        workCategory
        linkedinURL
        githubURL
        coverPhoto
        universityApplyFor
        courseApplyingFor
        createdAt
        updatedAt
        __typename
      }
      candidateProfileID
      url
      createdAt
      updatedAt
      candidateProfileS3objectsId
      __typename
    }
  }
`;
export const updateS3object = /* GraphQL */ `
  mutation UpdateS3object(
    $input: UpdateS3objectInput!
    $condition: ModelS3objectConditionInput
  ) {
    updateS3object(input: $input, condition: $condition) {
      id
      objectId
      type
      candidateProfile {
        id
        user
        userID
        fullName
        gender
        mobileNumber
        description
        matchedPercentage
        workStatus
        currentCity
        educationDetails
        keySkills
        workPreference
        preferredSalary
        photoURL
        sopURL
        isPublic
        t_c
        email
        roleApplyFor
        address
        interviewScheduleCount
        private_Candidate
        private_Candidate_Release
        workMode
        workCategory
        linkedinURL
        githubURL
        coverPhoto
        universityApplyFor
        courseApplyingFor
        createdAt
        updatedAt
        __typename
      }
      candidateProfileID
      url
      createdAt
      updatedAt
      candidateProfileS3objectsId
      __typename
    }
  }
`;
export const deleteS3object = /* GraphQL */ `
  mutation DeleteS3object(
    $input: DeleteS3objectInput!
    $condition: ModelS3objectConditionInput
  ) {
    deleteS3object(input: $input, condition: $condition) {
      id
      objectId
      type
      candidateProfile {
        id
        user
        userID
        fullName
        gender
        mobileNumber
        description
        matchedPercentage
        workStatus
        currentCity
        educationDetails
        keySkills
        workPreference
        preferredSalary
        photoURL
        sopURL
        isPublic
        t_c
        email
        roleApplyFor
        address
        interviewScheduleCount
        private_Candidate
        private_Candidate_Release
        workMode
        workCategory
        linkedinURL
        githubURL
        coverPhoto
        universityApplyFor
        courseApplyingFor
        createdAt
        updatedAt
        __typename
      }
      candidateProfileID
      url
      createdAt
      updatedAt
      candidateProfileS3objectsId
      __typename
    }
  }
`;
export const createPrivateScreening = /* GraphQL */ `
  mutation CreatePrivateScreening(
    $input: CreatePrivateScreeningInput!
    $condition: ModelPrivateScreeningConditionInput
  ) {
    createPrivateScreening(input: $input, condition: $condition) {
      id
      companyProfile {
        id
        userID
        email
        companyName
        companyUrl
        location
        websiteUrl
        contactPersonName
        contactPersonNumber
        contactPersonDesignation
        contactPersonEmail
        t_c
        companyUser
        remainingCredit
        holdCredit
        consumeCredit
        CompanySubscriptionPlan
        CompanySubscriptionStartDate
        CompanySubscriptionEndDate
        createdAt
        updatedAt
        __typename
      }
      companyProfileID
      jobDescription
      invitedCandidates
      createdAt
      releasedAt
      isReleased
      pointsDeducted
      jdID
      candidateEmail
      candidateName
      updatedAt
      companyProfilePrivateScreeningsId
      __typename
    }
  }
`;
export const updatePrivateScreening = /* GraphQL */ `
  mutation UpdatePrivateScreening(
    $input: UpdatePrivateScreeningInput!
    $condition: ModelPrivateScreeningConditionInput
  ) {
    updatePrivateScreening(input: $input, condition: $condition) {
      id
      companyProfile {
        id
        userID
        email
        companyName
        companyUrl
        location
        websiteUrl
        contactPersonName
        contactPersonNumber
        contactPersonDesignation
        contactPersonEmail
        t_c
        companyUser
        remainingCredit
        holdCredit
        consumeCredit
        CompanySubscriptionPlan
        CompanySubscriptionStartDate
        CompanySubscriptionEndDate
        createdAt
        updatedAt
        __typename
      }
      companyProfileID
      jobDescription
      invitedCandidates
      createdAt
      releasedAt
      isReleased
      pointsDeducted
      jdID
      candidateEmail
      candidateName
      updatedAt
      companyProfilePrivateScreeningsId
      __typename
    }
  }
`;
export const deletePrivateScreening = /* GraphQL */ `
  mutation DeletePrivateScreening(
    $input: DeletePrivateScreeningInput!
    $condition: ModelPrivateScreeningConditionInput
  ) {
    deletePrivateScreening(input: $input, condition: $condition) {
      id
      companyProfile {
        id
        userID
        email
        companyName
        companyUrl
        location
        websiteUrl
        contactPersonName
        contactPersonNumber
        contactPersonDesignation
        contactPersonEmail
        t_c
        companyUser
        remainingCredit
        holdCredit
        consumeCredit
        CompanySubscriptionPlan
        CompanySubscriptionStartDate
        CompanySubscriptionEndDate
        createdAt
        updatedAt
        __typename
      }
      companyProfileID
      jobDescription
      invitedCandidates
      createdAt
      releasedAt
      isReleased
      pointsDeducted
      jdID
      candidateEmail
      candidateName
      updatedAt
      companyProfilePrivateScreeningsId
      __typename
    }
  }
`;
export const createCompanySubscription = /* GraphQL */ `
  mutation CreateCompanySubscription(
    $input: CreateCompanySubscriptionInput!
    $condition: ModelCompanySubscriptionConditionInput
  ) {
    createCompanySubscription(input: $input, condition: $condition) {
      id
      companyProfile {
        id
        userID
        email
        companyName
        companyUrl
        location
        websiteUrl
        contactPersonName
        contactPersonNumber
        contactPersonDesignation
        contactPersonEmail
        t_c
        companyUser
        remainingCredit
        holdCredit
        consumeCredit
        CompanySubscriptionPlan
        CompanySubscriptionStartDate
        CompanySubscriptionEndDate
        createdAt
        updatedAt
        __typename
      }
      companyProfileID
      plan
      duration
      price
      startDate
      endDate
      createdAt
      updatedAt
      companyProfileCompanySubscriptionsId
      __typename
    }
  }
`;
export const updateCompanySubscription = /* GraphQL */ `
  mutation UpdateCompanySubscription(
    $input: UpdateCompanySubscriptionInput!
    $condition: ModelCompanySubscriptionConditionInput
  ) {
    updateCompanySubscription(input: $input, condition: $condition) {
      id
      companyProfile {
        id
        userID
        email
        companyName
        companyUrl
        location
        websiteUrl
        contactPersonName
        contactPersonNumber
        contactPersonDesignation
        contactPersonEmail
        t_c
        companyUser
        remainingCredit
        holdCredit
        consumeCredit
        CompanySubscriptionPlan
        CompanySubscriptionStartDate
        CompanySubscriptionEndDate
        createdAt
        updatedAt
        __typename
      }
      companyProfileID
      plan
      duration
      price
      startDate
      endDate
      createdAt
      updatedAt
      companyProfileCompanySubscriptionsId
      __typename
    }
  }
`;
export const deleteCompanySubscription = /* GraphQL */ `
  mutation DeleteCompanySubscription(
    $input: DeleteCompanySubscriptionInput!
    $condition: ModelCompanySubscriptionConditionInput
  ) {
    deleteCompanySubscription(input: $input, condition: $condition) {
      id
      companyProfile {
        id
        userID
        email
        companyName
        companyUrl
        location
        websiteUrl
        contactPersonName
        contactPersonNumber
        contactPersonDesignation
        contactPersonEmail
        t_c
        companyUser
        remainingCredit
        holdCredit
        consumeCredit
        CompanySubscriptionPlan
        CompanySubscriptionStartDate
        CompanySubscriptionEndDate
        createdAt
        updatedAt
        __typename
      }
      companyProfileID
      plan
      duration
      price
      startDate
      endDate
      createdAt
      updatedAt
      companyProfileCompanySubscriptionsId
      __typename
    }
  }
`;
export const createCompanyProfile = /* GraphQL */ `
  mutation CreateCompanyProfile(
    $input: CreateCompanyProfileInput!
    $condition: ModelCompanyProfileConditionInput
  ) {
    createCompanyProfile(input: $input, condition: $condition) {
      id
      userID
      email
      companyName
      companyUrl
      location
      websiteUrl
      contactPersonName
      contactPersonNumber
      contactPersonDesignation
      contactPersonEmail
      t_c
      companyUser
      remainingCredit
      privateScreenings {
        nextToken
        __typename
      }
      CompanySubscriptions {
        nextToken
        __typename
      }
      holdCredit
      consumeCredit
      companyJobDescription {
        nextToken
        __typename
      }
      CompanySubscriptionPlan
      CompanySubscriptionStartDate
      CompanySubscriptionEndDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCompanyProfile = /* GraphQL */ `
  mutation UpdateCompanyProfile(
    $input: UpdateCompanyProfileInput!
    $condition: ModelCompanyProfileConditionInput
  ) {
    updateCompanyProfile(input: $input, condition: $condition) {
      id
      userID
      email
      companyName
      companyUrl
      location
      websiteUrl
      contactPersonName
      contactPersonNumber
      contactPersonDesignation
      contactPersonEmail
      t_c
      companyUser
      remainingCredit
      privateScreenings {
        nextToken
        __typename
      }
      CompanySubscriptions {
        nextToken
        __typename
      }
      holdCredit
      consumeCredit
      companyJobDescription {
        nextToken
        __typename
      }
      CompanySubscriptionPlan
      CompanySubscriptionStartDate
      CompanySubscriptionEndDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCompanyProfile = /* GraphQL */ `
  mutation DeleteCompanyProfile(
    $input: DeleteCompanyProfileInput!
    $condition: ModelCompanyProfileConditionInput
  ) {
    deleteCompanyProfile(input: $input, condition: $condition) {
      id
      userID
      email
      companyName
      companyUrl
      location
      websiteUrl
      contactPersonName
      contactPersonNumber
      contactPersonDesignation
      contactPersonEmail
      t_c
      companyUser
      remainingCredit
      privateScreenings {
        nextToken
        __typename
      }
      CompanySubscriptions {
        nextToken
        __typename
      }
      holdCredit
      consumeCredit
      companyJobDescription {
        nextToken
        __typename
      }
      CompanySubscriptionPlan
      CompanySubscriptionStartDate
      CompanySubscriptionEndDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCompanyJobDescription = /* GraphQL */ `
  mutation CreateCompanyJobDescription(
    $input: CreateCompanyJobDescriptionInput!
    $condition: ModelCompanyJobDescriptionConditionInput
  ) {
    createCompanyJobDescription(input: $input, condition: $condition) {
      id
      companyProfile {
        id
        userID
        email
        companyName
        companyUrl
        location
        websiteUrl
        contactPersonName
        contactPersonNumber
        contactPersonDesignation
        contactPersonEmail
        t_c
        companyUser
        remainingCredit
        holdCredit
        consumeCredit
        CompanySubscriptionPlan
        CompanySubscriptionStartDate
        CompanySubscriptionEndDate
        createdAt
        updatedAt
        __typename
      }
      companyProfileID
      jobIDNo
      jobTitle
      selectedCategory
      selectedExperience
      skill
      jobDescription
      compensation
      benefits
      workMode
      createdAt
      updatedAt
      companyProfileCompanyJobDescriptionId
      __typename
    }
  }
`;
export const updateCompanyJobDescription = /* GraphQL */ `
  mutation UpdateCompanyJobDescription(
    $input: UpdateCompanyJobDescriptionInput!
    $condition: ModelCompanyJobDescriptionConditionInput
  ) {
    updateCompanyJobDescription(input: $input, condition: $condition) {
      id
      companyProfile {
        id
        userID
        email
        companyName
        companyUrl
        location
        websiteUrl
        contactPersonName
        contactPersonNumber
        contactPersonDesignation
        contactPersonEmail
        t_c
        companyUser
        remainingCredit
        holdCredit
        consumeCredit
        CompanySubscriptionPlan
        CompanySubscriptionStartDate
        CompanySubscriptionEndDate
        createdAt
        updatedAt
        __typename
      }
      companyProfileID
      jobIDNo
      jobTitle
      selectedCategory
      selectedExperience
      skill
      jobDescription
      compensation
      benefits
      workMode
      createdAt
      updatedAt
      companyProfileCompanyJobDescriptionId
      __typename
    }
  }
`;
export const deleteCompanyJobDescription = /* GraphQL */ `
  mutation DeleteCompanyJobDescription(
    $input: DeleteCompanyJobDescriptionInput!
    $condition: ModelCompanyJobDescriptionConditionInput
  ) {
    deleteCompanyJobDescription(input: $input, condition: $condition) {
      id
      companyProfile {
        id
        userID
        email
        companyName
        companyUrl
        location
        websiteUrl
        contactPersonName
        contactPersonNumber
        contactPersonDesignation
        contactPersonEmail
        t_c
        companyUser
        remainingCredit
        holdCredit
        consumeCredit
        CompanySubscriptionPlan
        CompanySubscriptionStartDate
        CompanySubscriptionEndDate
        createdAt
        updatedAt
        __typename
      }
      companyProfileID
      jobIDNo
      jobTitle
      selectedCategory
      selectedExperience
      skill
      jobDescription
      compensation
      benefits
      workMode
      createdAt
      updatedAt
      companyProfileCompanyJobDescriptionId
      __typename
    }
  }
`;
