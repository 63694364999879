import React, { useState, useEffect, useRef } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  listAdminAddCandidateContexts,
  listCandidateProactringData,
  listCandidateProfiles,
  listInterviewEvaluations,
} from "../../graphql/queries";
// import logo from "../assets/logo.svg"; // Adjusted import path for logo.svg
import logo from "../../assets/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons"; // Importing user circle icon
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import "./CompanyCandidateViewProfile.css"; // Import CSS file for styling
import { withAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import profilephoto from "../../assets/profile.png";
import Header from "../../components/header/Header";
// import { MdOutlineCancel } from "react-icons/md";
import { FaChartBar, FaCheckCircle, FaMailBulk, FaPhone, FaPhoneAlt, FaPhoneSlash, FaTimesCircle } from "react-icons/fa";
import { updateCandidateProfile } from "../../graphql/mutations";
import { FaFacebook, FaInstagram, FaLinkedin, FaLock, FaMailchimp, FaWhatsapp } from "react-icons/fa";
import { BiLogoGmail } from "react-icons/bi";
import { FaLockOpen } from "react-icons/fa";
import { LinkedinFilled } from "@ant-design/icons";
import { faShare } from "@fortawesome/free-solid-svg-icons"; // Importing user circle icon
import { MdEmail, MdSchedule } from "react-icons/md";
import { IoAddCircle, IoChatbubblesOutline } from "react-icons/io5";
import { IoMdSend } from "react-icons/io";
import { MdOutlineCancel } from "react-icons/md";
// that used in main compoent

// const fetchDataFromAPIs = async (
//   candidateProfileEmailId,
//   setProfiles,
//   setInterviewEvaluations
// ) => {
//   try {
//     // Fetch data from the first API
//     const adminContextResponse = await API.graphql(
//       graphqlOperation(listAdminAddCandidateContexts)
//     );
//     const adminContextItems =
//       adminContextResponse.data.listAdminAddCandidateContexts.items;
//     // Filter profiles based on candidateProfileEmailId
//     const filteredProfiles = adminContextItems.filter(
//       (profile) => profile.candidateEmail === pemail

//     );
//     setProfiles(filteredProfiles);

//     // Fetch data from the second API
//     const interviewEvaluationsResponse = await API.graphql(
//       graphqlOperation(listInterviewEvaluations, {
//         filter: {
//           candidateEmail: { eq: pemail },
//         },
//       })
//     );
//     const interviewEvaluationsItems =
//       interviewEvaluationsResponse.data.listInterviewEvaluations.items;
//     // Set interview evaluations
//     setInterviewEvaluations(interviewEvaluationsItems);
//   } catch (error) {
//     console.error("Error fetching data:", error);
//   }
// };

const CompanyCandidateViewProfile = ({
  email,
  fullName,
  photoURL,
  workStatus,
  description,
  keySkills,
  mobileNumber,
  githubURL,
  linkedinURL
}) => {
  const [profiles, setProfiles] = useState([]);
  const candidateProfileEmailId = localStorage.getItem(
    "candidateProfileEmailID"
  );
  const [candidateProfile, setCandidateProfile] = useState(null); // State to hold candidate profile details
  const [showProfileInfo, setShowProfileInfo] = useState(false);
  const [showEmail, setShowEmail] = useState(true);
  const [candidateEmail, setCandidateEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [interviewEvaluations, setInterviewEvaluations] = useState([]);
  const navigate = useNavigate();
  let a = 1;
  const [candiprofile, setCandiprofile] = useState([]);
  const [privateCandidate, setPrivateCandidate] = useState(false);
  const [privateCandidateId, setPrivateCandidateId] = useState();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [iscompanythere, setIscompanythere] = useState(false);
  const [copied, setCopied] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // ai code start
  // const [email, setEmail] = useState('');
  const [initialized, setInitialized] = useState(false);
  const [messages, setMessages] = useState([]);
  const [query, setQuery] = useState('');
  const [cookieHeader, setCookieHeader] = useState("")
  const [showInitButton, setShowInitButton] = useState(false); // State to show the initialize button
  const [apiResMessage, setApiResMessage] = useState("")


  // ai code 
  //  proacterinng code start
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [resumeURL, setResumeURL] = useState("")
  const [pdfResult, setPdfResult] = useState("")
  const [proteringMessage,setProteringMessage]=useState("")
  const [procteringloading,setProcteringloading]=useState(false)
  //  proactering code end

  console.log(
    "$$$$$$$$$$$$$$$$$$$$all date$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$",
    email,
    fullName,
    workStatus,
    description,
    keySkills,
    mobileNumber
  );
  // console.log("all date",keySkills)

  const fetchDataFromAPIs = async (
    candidateProfileEmailId,
    setProfiles,
    setInterviewEvaluations,
    email
  ) => {
    try {
      let nextToken = null;
      const adminContextItems = [];
      const interviewEvaluationsItems = [];

      // Fetch data from the first API with pagination
      do {
        const adminContextResponse = await API.graphql(
          graphqlOperation(listAdminAddCandidateContexts, {
            limit: 100,
            nextToken: nextToken,
          })
        );
        const adminContextPageItems =
          adminContextResponse.data.listAdminAddCandidateContexts.items;
        adminContextItems.push(...adminContextPageItems);
        nextToken =
          adminContextResponse.data.listAdminAddCandidateContexts.nextToken;
      } while (nextToken);

      // Filter profiles based on candidateProfileEmailId
      const filteredProfiles = adminContextItems.filter(
        (profile) => profile.candidateEmail === email
      );
      setProfiles(filteredProfiles);

      nextToken = null; // Reset nextToken for the next API call

      // Fetch data from the second API with pagination
      do {
        const interviewEvaluationsResponse = await API.graphql(
          graphqlOperation(listInterviewEvaluations, {
            filter: {
              candidateEmail: { eq: email },
            },
            limit: 100,
            nextToken: nextToken,
          })
        );
        const interviewEvaluationsPageItems =
          interviewEvaluationsResponse.data.listInterviewEvaluations.items;
        interviewEvaluationsItems.push(...interviewEvaluationsPageItems);
        nextToken =
          interviewEvaluationsResponse.data.listInterviewEvaluations.nextToken;
      } while (nextToken);

      // Set interview evaluations
      setInterviewEvaluations(interviewEvaluationsItems);
      setResumeURL(interviewEvaluationsItems[0])
      // console.log("=======================",)


      // realse mode code start
      const getCandidateProfile = async () => {
        try {
          // Retrieve the candidateProfileEmailID from local storage

          // Fetch candidate profiles
          const userProfileList = [];
          let nextToken = null;
          do {
            const userProfileResponse = await API.graphql(
              graphqlOperation(listCandidateProfiles, {
                filter: { email: { eq: email } },
                limit: 100,
                nextToken: nextToken,
              })
            );
            userProfileList.push(
              ...userProfileResponse.data.listCandidateProfiles.items
            );
            nextToken =
              userProfileResponse.data.listCandidateProfiles.nextToken;
          } while (nextToken);

          if (userProfileList.length > 0) {
            // console.log("*-*-*---****-*-*-*-*-*--**-*-*-**-*-*-*-*-*--*-*",userProfileList[0].private_Candidate)
            setPrivateCandidate(userProfileList[0].private_Candidate);
            setPrivateCandidateId(userProfileList[0].id);
            // console.log("*-*-*---****-*-*-*-*-*--**-*-*-**-*-*-*-*-*--*-*", userProfileList)
          }
        } catch (error) {
          console.log("Error fetching candidate profiles:", error);
        }
      };

      getCandidateProfile(email);


      // ai code start

      // const initializeChat = async () => {
      //   if (email.trim() === '') {
      //     alert('Please enter a valid email address.');
      //     return;
      //   }
      //   try {
      //     // const response = await axios.post('/initialize', { email: email });
      //     const modifiedEmail = email.replace(/\.com$/, '');

      //     const response = await fetch("https://33e4-103-164-240-172.ngrok-free.app/initialize", {
      //       method: 'POST',
      //       headers: {
      //         'Content-Type': 'application/json',
      //         'Cookie': 'access_token=dattatray@varahitechnologies; Secure; HttpOnly; Path=/'
      //       },
      //       body: JSON.stringify({ email: modifiedEmail }),
      //     });

      //     const data = response.json()


      //     // console.log("llllll:",response.headers.getSetCookie)
      //     // setCookieHeader(response.headers.get('Set-Cookie'))

      //     console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh", data)

      //     const setCookie = response.headers.get('set-cookie');
      //     console.log('Set-Cookie header:', setCookie);


      //     if (data.error) {
      //       alert(data.error);
      //     } else {
      //       setInitialized(true);
      //     }
      //   } catch (error) {
      //     console.error('Error initializing chat:', error);
      //   }
      // };

      // initializeChat()

      // ai code 

      //  proactering code start

      const fetchProcteratingData = async () => {

        let nextToken = null;
        const allItems = [];

        try {
          do {
            // Fetch data from the API with pagination
            const response = await API.graphql(graphqlOperation(listCandidateProactringData, {
              filter: { candidateEmail: { eq: email } },
              limit: 100,
              nextToken: nextToken,
            }));

            const { items, nextToken: newNextToken } = response.data.listCandidateProactringData;

            // Append fetched items to the allItems array
            allItems.push(...items);

            // Update nextToken for the next iteration
            nextToken = newNextToken;
          } while (nextToken); // Continue if there is a nextToken

          // Set the fetched data
          setData(allItems);
        } catch (err) {
          // Handle any errors
          setError(err.message || 'An error occurred');
        } finally {
          setLoading(false);
        }

      }

      fetchProcteratingData()

      //  proactering code end


      // release mode code end
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // share code start


  const ShareModal = ({ isOpen, onClose, onShareVia, onCopyURL, copied }) => {
    const modalRef = useRef();

    const handleCloseModal = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onClose();
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleCloseModal);
      return () => {
        document.removeEventListener('mousedown', handleCloseModal);
      };
    }, []);

    return (

      <>
        {isOpen && (
          <div className="fixed inset-0 flex items-end justify-center z-10">
            <div className="fixed inset-0 bg-black opacity-50"></div>
            <div className="absolute top-96" ref={modalRef}>
              <div className="rounded-md shadow-lg p-6 flex flex-col items-center" style={{
                border: '1px solid purple',
                background: "rgb(13 12 26 / 88%)",
                padding: '39px 44px'
              }} >
                <button className="absolute top-2 right-2 " onClick={onClose}>
                  &times;
                </button>
                <div className="flex flex-col gap-2">
                  <div>
                    <h6 className="text-center">Share with</h6>
                  </div>
                  <div className="flex gap-3" >
                    <button onClick={onCopyURL}><FontAwesomeIcon icon={faCopy} className="h-[32px] w-[35px]" /></button>
                    <button onClick={() => onShareVia("whatsapp")}   > <FaWhatsapp className="h-[32px] w-[35px]" />  </button>
                    <button onClick={() => onShareVia("linkedin")}><FaLinkedin className="h-[32px] w-[35px]" /></button>
                    <button onClick={() => onShareVia("facebook")}><FaFacebook className="h-[32px] w-[35px]" /></button>
                    <button onClick={() => onShareVia("instagram")}><FaInstagram className="h-[32px] w-[35px]" /></button>
                    <button onClick={() => onShareVia("email")}><BiLogoGmail className="h-[32px] w-[35px]" /></button>
                  </div>
                </div>
                {copied && <div>Link Copied!</div>}
              </div>
            </div>
          </div>
        )}
      </>

    );
  };


  const handleShare = () => {
    setShowModal(!showModal);
  };

  const handleShareVia = (platform) => {
    const encodedEmail = encodeURIComponent(email);
    const shareableURL = `${window.location.origin}/ShowMySkills?email=${encodedEmail}`;
    let shareURL = "";

    switch (platform) {
      case "whatsapp":
        shareURL = `https://api.whatsapp.com/send?text=${encodeURIComponent(shareableURL)}`;
        break;
      case "linkedin":
        shareURL = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareableURL)}`;
        break;
      case "facebook":
        shareURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareableURL)}`;
        break;
      case "instagram":
        shareURL = `https://www.instagram.com/share?url=${encodeURIComponent(shareableURL)}`;
        break;
      case "email":
        const subject = encodeURIComponent("Check out this profile");
        const body = encodeURIComponent(`Hi, I thought you might be interested in this profile: ${shareableURL}`);
        shareURL = `mailto:?subject=${subject}&body=${body}`;
        break;
      default:
        break;
    }

    window.open(shareURL);
    setShowModal(false);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  const handleCopyURL = () => {
    const shareableURL = `${window.location.origin}/ShowMySkills?email=${encodeURIComponent(email)}`;
    navigator.clipboard.writeText(shareableURL);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  //share code end

  // Fetch candidate profile details from localStorage on component mount
  useEffect(() => {
    const candidateProfileEmailId = localStorage.getItem(
      "candidateProfileEmailID"
    );
    if (candidateProfileEmailId) {
      const storedProfile = JSON.parse(
        localStorage.getItem("candidateProfile")
      );
      setCandidateProfile(storedProfile);
    }

    const companyProfileID = localStorage.getItem("CompanyProfileID");

    // Set privateCandidate to true if CompanyProfileID exists
    if (companyProfileID) {
      setIscompanythere(true);
    }
  }, []);

  useEffect(() => {
    fetchDataFromAPIs(
      candidateProfileEmailId,
      setProfiles,
      setInterviewEvaluations,
      email
    );
  }, [candidateProfileEmailId]);
  console.log(`profiles`, profiles);
  console.log(`interviewEvaluations`, interviewEvaluations);

  // Function to handle showing profile info after interview completion
  const handleShowProfileInfo = () => {
    // Check if the candidateProfileEmailID is present in listAdminAddCandidateContexts
    if (localStorage.getItem("candidateProfileEmailID")) {
      setShowProfileInfo(true);
    } else {
      alert("Candidate profile not found in admin list.");
    }
  };

  useEffect(() => {
    // Retrieve the candidateProfileEmailID from local storage
    const candidateProfileEmailID = localStorage.getItem(
      "candidateProfileEmailID"
    );

    // Update the content of the span element with the candidateProfileEmailID
    setCandidateEmail(candidateProfileEmailID);
  }, []);
  // Function to toggle modal
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    console.log("Modal toggled");
  };
  console.log("Photo url", photoURL);

  // const handleNotInterested = async () => {
  //   try {
  //     // Update privateCandidate state to false
  //     setPrivateCandidate(false);

  //     // Update candidate profile in GraphQL
  //     const mutationInput = {
  //       id:privateCandidateId,
  //       // Provide the necessary input for the updateCandidateProfile mutation
  //       // For example, if you want to update privateCandidate field, you can do like this
  //       private_Candidate: false, // Set privateCandidate field to false
  //       // Add other fields if needed
  //     };

  //     await API.graphql(
  //       graphqlOperation(updateCandidateProfile, { input: mutationInput })
  //     );

  //     console.log("Candidate profile updated successfully.");
  //   } catch (error) {
  //     console.error("Error updating candidate profile:", error);
  //   }
  // };
  const handleNotInterested = () => {
    // Show confirmation dialog
    setShowConfirmation(true);
  };

  const confirmNotInterested = async () => {
    try {
      // Update privateCandidate state to false
      setPrivateCandidate(false);

      // Update candidate profile in GraphQL
      const mutationInput = {
        id: privateCandidateId, // Assuming you have the ID stored somewhere
        private_Candidate: false, // Set privateCandidate field to false
        // Add other fields if needed
      };

      await API.graphql(
        graphqlOperation(updateCandidateProfile, { input: mutationInput })
      );

      console.log("Candidate profile updated successfully.");

      // Hide confirmation dialog after action is confirmed
      setShowConfirmation(false);
    } catch (error) {
      console.error("Error updating candidate profile:", error);
    }
  };


  //  ai bot code start

  const initializeChat = async () => {
    if (email.trim() === '') {
      alert('Please enter a valid email address.');
      return;
    }
    try {
      // const response = await axios.post('/initialize', { email: email });
      // const modifiedEmail = email.replace(/\.com$/, '');

      const response = await fetch("http://20.70.140.142:5000/initialize", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email }),
      });

      const data = await response.json();
      // if (data.error) {
      // alert(data.error);
      // } else {
      console.log("999999999999999999999999999999999", data.message)
      setApiResMessage(data.message)
      if (data.message === "QA system initialized successfully!") {

        setInitialized(true);
        addMessage('Hi I am GetScreend bot I can help you to evalute this candidate better !', 'bot-message', true); // Display initial message
        setShowInitButton(false);

      }

      if (data.message === "Embeddings not found. Generation started. They will be ready in approximately 5 minutes.") {

        setInitialized(true);
        addMessage('Hi I am GetScreend bot I can help you to evalute this candidate better !', 'bot-message', true); // Display initial message
        addMessage('AI is churning data for the first time , Kindly wait for a minute or two to load the AI .', 'bot-message', false);


        setTimeout(() => {
          addMessage('Now ready!', 'bot-message', true);
          setShowInitButton(true); // Show the initialize button again
        }, 120000); // 2 minutes in milliseconds

      }
      // setInitialized(true);
      // addMessage('Hi I am GetScreend bot I can help you to evalute this candidate better !', 'bot-message', true); // Display initial message
      // console.log("++++++++++++++++++++++++++++++++++++++++++++++++++++++", initialized)
      // }
    } catch (error) {
      console.error('Error initializing chat:', error);
    }
  };

  console.log("++++++++++++++++++++++++++++++++++++++++++++++++++++++", initialized)

  const sendMessage = async () => {
    if (query.trim() === '') return;
    addMessage(query, 'user-message');
    setQuery('');
    try {
      // const response = await axios.post('/ask', { email: email, query: query });

      // const modifiedEmail = email.replace(/\.com$/, '');

      const response = await fetch('http://20.70.140.142:5000/ask', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Cookie': cookieHeader
        },
        body: JSON.stringify({ email: email, query: query }),
      });
      console.log("...:", response)
      // const data = response.data;
      const data = await response.json();

      console.log("ttttttttttttttttttttttttttttttttttttttt", response)

      console.log("cookieHeader", data.answer)

      addMessage(data.answer ? data.answer : data.error, 'bot-message');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  // original code start
  // const addMessage = (text, className) => {
  //   console.log(",.,..,.,.,.,.,.,.,.,.,.,..,.,.,.,.,.,.,.", text, className)
  //   setMessages(prevMessages => [...prevMessages, { text, className }]);
  // };
  // original code end
  const addMessage = (text, className, isFirstMessage = false) => {
    if (isFirstMessage) {
      // Insert at the beginning of the messages array
      setMessages([{ text, className }, ...messages]);
    } else {
      // Append to the end of the messages array
      setMessages(prevMessages => [...prevMessages, { text, className }]);
    }
  };



  const closeChat = () => {
    setInitialized(false);
    setMessages([]);
    // setEmail('');
    setQuery('');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (apiResMessage === "QA system initialized successfully!") {
        sendMessage();
      }
    }
  };

  // ai bot code end
  // proactering code start

  const showProteringDadaModel = async () => {
    // try {
    //   // const response = await fetch('YOUR_PROCATERING_API_URL'); // Replace with your API URL
    //   // const result = await response.json();
    // setModalContent(result); // Set the content to display in the modal
    setIsModalVisible(true); // Show the modal
    // } catch (error) {
    //   console.error('Error fetching procatering data:', error);
    // }


    // let nextToken = null;
    // const allItems = [];

    // try {
    //   do {
    //     // Fetch data from the API with pagination
    //     const response = await API.graphql(graphqlOperation(listCandidateProactringData, {
    //       filter: { candidateEmail: { eq: email } },
    //       limit: 100,
    //       nextToken: nextToken,
    //     }));

    //     const { items, nextToken: newNextToken } = response.data.listCandidateProactringData;

    //     // Append fetched items to the allItems array
    //     allItems.push(...items);

    //     // Update nextToken for the next iteration
    //     nextToken = newNextToken;
    //   } while (nextToken); // Continue if there is a nextToken

    //   // Set the fetched data
    // setData(allItems);
    // } catch (err) {
    //   // Handle any errors
    //   setError(err.message || 'An error occurred');
    // } finally {
    //   setLoading(false);
    // }

    //  candidate Profile  code ***********************

    // let nextToken = null;
    // let candidateProfileItem = null;

    // try {
    //   do {
    //     const candidateProfileResponse = await API.graphql(
    //       graphqlOperation(listCandidateProfiles, {
    //         filter: {
    //           email: { eq: email },
    //         },
    //         limit: 100, // Adjust the limit as needed
    //         nextToken: nextToken,
    //       })
    //     );

    //     const items = candidateProfileResponse.data.listCandidateProfiles.items;

    //     if (items.length > 0) {
    //       candidateProfileItem = items[0];
    //       break; // Exit the loop if a profile is found
    //     } else {
    //       // Continue to the next iteration if there are more records to fetch
    //       nextToken = candidateProfileResponse.data.listCandidateProfiles.nextToken;
    //     }
    //   } while (nextToken);

    //   if (candidateProfileItem) {
    //     // Set state or handle the candidate profile data as needed
    //     setData(candidateProfileItem);

    //   } else {
    //     console.log('No candidate profile found for the provided email.');
    //   }
    // } catch (error) {
    //   console.error('Error fetching data:', error);
    // }

    // candidate Profile code end



    // Procetring data check code start ********************

    let nextToken = null;
    let candidateDataItems = [];

    try {
      do {
        // Fetch the candidate data with the specified email
        const response = await API.graphql(
          graphqlOperation(listCandidateProactringData, {
            filter: {
              candidateEmail: { eq: email }
            },
            limit: 100, // Adjust the limit as needed
            nextToken: nextToken
          })
        );

        const items = response.data.listCandidateProactringData.items;
        const fetchedNextToken = response.data.listCandidateProactringData.nextToken;

        // Collect items from this page
        candidateDataItems = [...candidateDataItems, ...items];

        // Update nextToken for the next iteration, if any
        nextToken = fetchedNextToken;

      } while (nextToken); // Continue fetching if there's a nextToken

      if (candidateDataItems.length > 0) {
        // original code start

        console.log('Candidate data retrieved444444444444444444444444444444444:', candidateDataItems)
        setData(candidateDataItems)
        setPdfResult(candidateDataItems[0].pdfResult)

        //  original code end

        //  test start


        //  test end

      } else {
        console.log('No candidate data found for the provided email.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }

    //  Procetring Data Check Code end

  };

  // 64 check

  // const isValidBase64 = (data) => {
  //   const base64Pattern = /^data:application\/pdf;base64,[A-Za-z0-9+/=]+$/;
  //   return base64Pattern.test(data);
  // };



  // Hide modal
  const hideModal = () => {
    setIsModalVisible(false);
  };

  const genrateProtering = async () => {

    setProcteringloading(true)
    try {
      // Replace 'YOUR_API_ENDPOINT' with your API URL
      // const response = await fetch('');
      const response = await fetch('https://0b79-20-70-140-142.ngrok-free.app/analyze_video', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email }),
      });

      // Check if the response is ok (status code in the range 200-299)
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Parse the JSON data
      const result = await response.json();

      // Update state with the fetched data
      // setData(result);
      console.log("888888888888888888888888888888888888888", result)
      console.log("888888888888888888888888888888888888888", result)

      const{message} = result.message
      console.log("mmmmmmmmmmmmmmmmmmmmmmmmmm",message)
      setProteringMessage(message)
      window.location.reload();
    } catch (error) {
      // Handle errors (e.g., network issues, invalid JSON)
      setError(error);
    } finally {
      // Set loading to false regardless of success or failure
      // setLoading(false);
      setProcteringloading(false)
    }

  }

  // proactering code end


  console.log("///////////////////////////////////////////////////", data)
  console.log("ccccccccccccccccccccccccccccccccccccccccccccccccccc", pdfResult)
  return (
    <div>
      {/* Header Section */}
      {/* Testing purops below */}
      {profiles ? (
        <div>{console.log("true")}</div>
      ) : (
        <div>{console.log("false")}</div>
      )}

      {/* Testing puropse Abow */}
      {/* Container for centering content */}

      {/* main original code start */}
      <div>
        <Header />
      </div>
      <div className="container" style={{ color: "#fff", width: "80%" }}>
        {" "}
        {/* Increased width to 80% */}
        {/* Your JSX code to display candidate details */}
        {/* {profiles &&  profiles.length >= 0 && */}
        {/* {   */}
        <div className="card" style={{ marginTop: "50px" }}>
          <div className="card-body">
            <div
              className="profile-header"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <img
                  // src={candidateProfile.photoURL}
                  src={photoURL || profilephoto}
                  alt="Atul"
                  className="profile-img"
                />
                <div style={{ marginTop: "15px" }}>
                  <h5 className="card-title" style={{ color: "#fff" }}>
                    {/* {`${candidateProfile.fullName}`} */}
                    {/* {`${pfullName}`} */}
                    Name : {fullName}
                  </h5>
                  <strong style={{ color: "#fff" }}>
                    {" "}
                    {/* Work Status 2: {candidateProfile.workStatus} */}
                    {/* Work Status 2: {pworkStatus}  */}
                    Work Status : {workStatus}
                  </strong>
                  {/* <strong style={{ color: "#fff" }}>
                    <FaPhone/> : {mobileNumber}
                  </strong>
                  <strong style={{ color: "#fff" }}>
                    <FaMailBulk/> : {email}
                  </strong> */}
                  <div className="flex items-center text-white">
                    {/* <FaPhone className="mr-2" /> : {mobileNumber} */}
                    <FaPhoneAlt className="mr-2" />  : {mobileNumber}
                  </div>
                  <div className="text-white flex">
                    {/* <FaMailBulk className="mr-2" /> : {email} */}
                    <MdEmail className="mr-2 mt-1" /> : {email}
                  </div>
                  {/* Assuming workStatus represents experience */}
                  <br />
                </div>
              </div>

              {
                linkedinURL && (
                  <div className="ml-11" style={{ marginLeft: '6.5rem', marginBottom: '12px' }}>
                    <a href={`https://${linkedinURL}`} target="_blank" rel="noopener noreferrer">
                      <i className="fa-brands fa-linkedin" style={{ fontSize: '30px', color: '#fff', marginTop: '10px', cursor: 'pointer' }} />
                    </a>
                  </div>
                )
              }

              {

                githubURL && (
                  <div className="" style={{ marginBottom: '12px' }}>
                    <a href={`${githubURL}`} target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-github" style={{ fontSize: '30px', color: '#fff', marginTop: '10px', cursor: 'pointer' }} />
                    </a>
                  </div>
                )

              }

              {/* share code start */}
              <div>
                <div className="share-dropdown">
                  <button className="share-button text-2xl" onClick={handleShare}>
                    <FontAwesomeIcon icon={faShare} />
                  </button>
                </div>
                <ShareModal
                  isOpen={showModal}
                  onClose={() => setShowModal(false)}
                  onShareVia={handleShareVia}
                  onCopyURL={handleCopyURL}
                  copied={copied}
                />
              </div>
              {/* share code end */}

            </div>
            <br />
            <p className="card-text" style={{ color: "#fff" }}>
              {/* {candidateProfile.description} */}
              Description : {description}
            </p>
            <div className="skillss gap-2">
              <br />
              Skill :
              {keySkills &&
                keySkills.split(",").map((skill, skillIndex) => (
                  <span key={skillIndex} className="skill-tag">
                    {skill.trim()}
                  </span>
                ))}
            </div>
            {/* { profiles.map((profile, index) => ( 
                <div key={index}>
                <h5 className="card-title" style={{ color: "#fff", marginTop:"40px" }}>
                  Interview Video
                </h5>
                <video
                  controls
                  style={{ width: "100%", border: "2px solid #ccc" }}
                >
                  <source src={profile.candidateVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div className="transcript-container">
                  <CustomizedResumeText
                    candidateContextTextFile={profile.candidateContextTextFile}
                  />
                </div>
                <div className="transcript-container">
                  <CustomizedResumeRating
                    candidateContextTextFile={profile.candidateContextTextFile}
                  />
                </div>
                </div>
                 ))} */}
          </div>
        </div>
        {/* }  */}
      </div>
      {/* renering code start */}
      {/* profiles || interviewEvaluations */}
      {(profiles && profiles.length > 0) ||
        (interviewEvaluations && interviewEvaluations.length > 0) ? (
        <div className="container" style={{ color: "#fff", width: "80%" }}>
          {" "}
          {/* {interviewEvaluations &&  interviewEvaluations.length >= 0 && 
          interviewEvaluations.map((evaluation, index) => ( */}
          {/*  */}
          <div className="card" style={{ marginTop: "50px" }}>
            <div className="card-body">
              {/*  */}
              {/* <div className="profile-header">
                  <img
                    src={photoURL}
                    alt="sachin"
                    className="profile-img"
                  />
                  <div>
                    <h5 className="card-title" style={{ color: "#fff" }}>
                      
                     Name : {fullName}
                    </h5>
                    <strong style={{ color: "#fff" }}>
                      {" "}
                    
                      Work Status : {workStatus}
                    </strong>
                    <br />
                  </div>
                </div>
                <br />
                <p className="card-text" style={{ color: "#fff" }}>
                Description : {description}
                </p>
                <div className="skillss">
                  
                  Skill :
                  {keySkills &&
                    keySkills
                      .split(",")
                      .map((skill, skillIndex) => (
                        <span key={skillIndex} className="skill-tag">
                          {skill.trim()}
                        </span>
                      ))}


                </div> */}

              {profiles.map((profile, index) => (
                <div key={index}>
                  <h5
                    className="card-title"
                    style={{ color: "#fff", marginTop: "40px" }}
                  >
                    Interview Video
                  </h5>
                  <video
                    controls
                    style={{ width: "100%", border: "2px solid #ccc" }}
                    controlsList="nodownload"
                  >
                    <source src={profile.candidateVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <div className="transcript-container">
                    <CustomizedResumeText
                      candidateContextTextFile={
                        profile.candidateContextTextFile
                      }
                    />
                  </div>
                  <div className="transcript-container">
                    <CustomizedResumeRating
                      candidateContextTextFile={
                        profile.candidateContextTextFile
                      }
                    />
                  </div>
                </div>
              ))}

              {/*  */}
              {interviewEvaluations.map((evaluation, index) => (
                <div key={index}>
                  <h5
                    className="card-title"
                    style={{ color: "#fff", marginTop: "40px" }}
                  >
                    Interview Video
                  </h5>
                  <video
                    controls
                    style={{ width: "100%", border: "2px solid #ccc" }}
                    controlsList="nodownload"
                  >
                    <source src={evaluation.videoURL} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <h5
                    className="card-title"
                    style={{ color: "#fff", margin: "1rem" }}
                  >
                    Interview Transcript
                  </h5>
                  <div className="transcript-container">
                    {evaluation.transcript &&
                      JSON.parse(evaluation.transcript).map((item, idx) => (
                        <div key={idx}>
                          <p>
                            <strong>{item.speaker}:</strong> {item.message}
                          </p>
                        </div>
                      ))}
                  </div>
                  <h5
                    className="card-title"
                    style={{ color: "#fff", margin: "1rem" }}
                  >
                    Interview Evaluation
                  </h5>
                  <div className="transcript-container">
                    {evaluation.evaluation &&
                      Object.entries(JSON.parse(evaluation.evaluation)).map(
                        ([criterion, data], idx) => (
                          <div key={idx}>
                            <p>
                              <strong>{criterion}:</strong>{" "}
                              {data.score ? data.score : "N/A"} /{" "}
                              {data.max_score ? data.max_score : "N/A"}
                            </p>
                            <p>
                              <strong>Comments:</strong>{" "}
                              {data.comments ? data.comments : "N/A"}
                            </p>
                          </div>
                        )
                      )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/*  */}
          {/* ))} */}
          {iscompanythere === true ? (
            <div className="mt-4 mb-10">
              <button className="border-1 border-purple-700 hover:bg-purple-700 hover:border-transparent text-white font-medium capitalize py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-4">
                <FaCheckCircle className="inline-block mr-1" />
                Interested
              </button>
              {privateCandidate && (
                <button
                  className="border-1 border-purple-700 hover:bg-purple-700 hover:border-transparent text-white font-medium capitalize py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  onClick={handleNotInterested}
                >
                  <FaTimesCircle className="inline-block mr-1" />
                  Not Interested
                </button>
              )}
            </div>
          ) : (
            ""
          )}
          {/*  */}
          {showConfirmation && (
            <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-gray-800 bg-opacity-75">
              <div className="bg-[#1a1934] border-1   p-4 rounded shadow-md">
                <p className="mb-4">
                  Are you sure you want to proceed? This action is irreversible.
                </p>
                <div className="flex justify-between">
                  <button
                    className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-2"
                    onClick={() => setShowConfirmation(false)} // Hide confirmation dialog
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    onClick={confirmNotInterested} // Proceed with the action
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          )}
          {/*  */}
        </div>
      ) : (
        <div>
          <h1 className="text-center text-red-700">Not yet Screened</h1>
        </div>
      )}

      {/*rendering code end  */}
      {console.log("message", messages)}



      <div>
        {/* original code start ai Bot */}

        {/* {!initialized ? (
          <div>
            <input
              type="text"
              value={email}
              // onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="bg-yellow-400"
            />
            <button onClick={initializeChat}>Initialize Chat</button>
          </div>
        ) : (
          <div className="flex items-center justify-center h-screen  bg-[#1a1934]">
            <div className="w-full max-w-md">
              <div className="bg-white text-black shadow-lg rounded-lg overflow-hidden">
                <div className="chat-window p-4 h-64 overflow-y-auto">
                  {messages.map((message, index) => (
                    <div key={index} className={`p-2 mb-2 rounded flex ${message.className}`}>
                      <h1 className="text-xl">{message.className} : </h1>
                      <h1 className="text-xl">{message.text}</h1>
                    </div>
                  ))}
                </div>
                <div className="p-4 border-t border-gray-200">
                  <input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Type your message..."
                    className="w-full p-2 mb-2 bg-red-600 text-white rounded"
                  />
                  <button
                    onClick={sendMessage}
                    className="w-full bg-blue-500 text-white p-2 rounded"
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        )} */}

        {/*  original code ai Bot end */}

      </div>

      {/* testt code Ai Bot start */}

      {
        interviewEvaluations.length > 0 && (
          <div className="fixed bottom-4 right-4 md:bottom-8 md:right-8 lg:max-w-md w-full md:w-auto">
            {!initialized ? (
              <div className=" rounded-lg shadow-lg">
                {/* <button onClick={initializeChat} className="w-full bg-[#9900FF] p-4 text-white rounded">
                <IoChatbubblesOutline className="h-6 w-6 mr-2 inline-block" />
                Start Chat
              </button> */}
                <button onClick={showProteringDadaModel} className="w-full bg-green-600 p-4 text-white rounded mt-2">
                  Get Procterating Data
                </button>
              </div>
            ) : (
              <div className="bg-white text-purple-600 rounded-lg shadow-lg overflow-hidden">
                <div className="chat-window h-80 md:h-96 overflow-y-auto">
                  {messages.map((message, index) => (
                    <div key={index} className={`p-2 mb-2 rounded flex ${message.className === 'bot-message' ? 'justify-start' : 'justify-end'}`}>
                      {message.className === 'bot-message' && (
                        <div className="w-8 h-8 bg-blue-500 rounded-full mr-2"></div>
                      )}
                      <div className="flex flex-col">
                        <span className="text-xs text-gray-500">{message.className === 'bot-message' ? 'Getscreened' : 'You'}</span>
                        <p className="text-sm p-2 bg-gray-100 rounded-lg">{message.text}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="p-4 border-t border-gray-200 flex gap-2 justify-between items-center">
                  <input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    // onKeyPress={handleKeyPress}
                    onKeyDown={handleKeyDown}
                    placeholder="Type your message..."
                    className="w-full p-2 bg-gray-100 rounded"
                  />{
                    apiResMessage === "QA system initialized successfully!" ? (

                      <button onClick={sendMessage} className="bg-blue-500 text-white p-2 rounded">
                        <IoMdSend />
                      </button>

                    ) : (
                      <></>
                    )
                  }
                  {
                    showInitButton && (
                      <div>
                        <button onClick={initializeChat} className="bg-blue-500 text-white p-2 rounded">
                          <MdSchedule />
                        </button>
                      </div>
                    )
                  }

                  <button onClick={closeChat} className="text-white bg-red-700 p-2 rounded"><MdOutlineCancel /></button>
                </div>
              </div>
            )}
            {/* reintailze button start */}

            {/* {showInitButton && (
            <div className="mt-4">
              <button onClick={initializeChat} className="w-full bg-[#9900FF] p-4 text-white rounded">
                <IoChatbubblesOutline className="h-6 w-6 mr-2 inline-block" />
                Re-initialize Chat
              </button>
            </div>
          )} */}

            {/* reintialised button end */}

          </div>
        )
      }

      {/*  test code Ai bot end */}


      {/* procatring code start */}

      {isModalVisible && (
        // <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        //   <div className="bg-yellow-800 p-4 rounded-lg w-11/12 md:w-1/2 lg:w-1/3 ">
        //     <h2 className="text-xl font-bold mb-4 text-black">Procterating Data</h2>
        //     <pre>{modalContent}</pre>
        //     <div className="mt-4 flex gap-4">
        //     {pdfResult ? (
        //       <div className="relative text-white" style={{ height: '400px', width: '400px' }}>
        //         {/* <object
        //           data={pdfResult}
        //           type="application/pdf"
        //           width="50%"
        //           height="50%"
        //         >
        //           <embed
        //             src={pdfResult}
        //             type="application/pdf"
        //             width="50%"
        //             height="50%"
        //           /> */}
        //           <p className="text-black">Your browser does not support PDFs. <a href={pdfResult}>Download the PDF</a>.</p>
        //         {/* </object> */}
        //       </div>
        //     ) : (
        //       <p>Loading...</p>
        //     )}
        //       <button onClick={genrateProtering} className="bg-green-600 text-white p-2 rounded">
        //         Genrate Protering Data
        //       </button>
        //       <button onClick={hideModal} className="bg-red-700 text-white p-2 rounded">
        //         Close
        //       </button>
        //       {/* <button onClick={initializeChat} className="bg-blue-500 text-white p-2 rounded">
        //         Begin Chat
        //       </button> */}
        //     </div>
        //   </div>
        // </div>

        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white border-1 p-4 rounded-lg w-11/12 md:w-1/2 lg:w-1/3 flex flex-col items-center">
            <h2 className="text-xl font-bold mb-4 text-black">Proctoring Data</h2>

            <div className="flex flex-col items-center justify-center mb-4">
              {pdfResult ? (
                <p className="text-black">
                  Proctoring Result : <a href={pdfResult} className="underline">Click here To Download</a>.
                </p>
              ) : (
                <p className="text-gray-800">Processing may take up to 5 minutes. Thank you for your patience!</p>
              )}
            </div>

            <div className="flex flex-col items-center gap-2">  
           <h1>{proteringMessage}</h1>

              {!pdfResult && (
                <button
                  onClick={genrateProtering}
                  className="bg-green-600 text-white p-2 rounded w-full mb-2"
                  disabled={procteringloading}
                >
                  {procteringloading ? 'Genrating...' : 'Generate Result'}
                </button>
              )}

              <button
                onClick={hideModal}
                className="bg-red-700 text-white p-2 rounded w-full"
              >
                Close
              </button>
            </div>
          </div>
        </div>


      )}

      {/* proactering code end */}


      {/* main original course end */}
    </div>
  );
};

// Component to fetch and display candidate resume text and rating
const CustomizedResumeText = ({ candidateContextTextFile }) => {
  const [resumeText, setResumeText] = useState("");
  const [filteredResumeText, setFilteredResumeText] = useState("");
  const [candidateRating, setCandidateRating] = useState("");

  useEffect(() => {
    const fetchResumeText = async () => {
      try {
        const response = await fetch(candidateContextTextFile);
        const text = await response.text();
        setResumeText(text);

        // Extracting required sections from the resume text
        const start = text.indexOf("Full Interview TranScript:");
        const end =
          text.indexOf(
            "******************************************Candidate Reting:***"
          ) || text.length; // Set end to text length if rating section not found
        const filteredText = text.substring(start, end);
        setFilteredResumeText(filteredText);

        // Extracting candidate rating
        const ratingStart = text.indexOf(
          "******************************************Candidate Reting:***"
        );
        if (ratingStart !== -1) {
          const filteredText = text.substring(ratingStart);
          setCandidateRating(filteredText);
        }
      } catch (error) {
        console.error("Error fetching resume text:", error);
      }
    };

    fetchResumeText();
  }, [candidateContextTextFile]);

  return (
    <>
      <div>
        <h5 style={{ color: "#9900ff" }}>TranScript</h5>
        <pre>{filteredResumeText}</pre>
      </div>
    </>
  );
};

// Component to fetch and display candidate resume text and rating
const CustomizedResumeRating = ({ candidateContextTextFile }) => {
  const [resumeText, setResumeText] = useState("");
  const [filteredResumeText, setFilteredResumeText] = useState("");
  const [candidateRating, setCandidateRating] = useState("");

  useEffect(() => {
    const fetchResumeText = async () => {
      try {
        const response = await fetch(candidateContextTextFile);
        const text = await response.text();
        setResumeText(text);

        // Extracting required sections from the resume text
        const start = text.indexOf("Full Interview TranScript:");
        const end =
          text.indexOf(
            "******************************************Candidate Reting:***"
          ) || text.length; // Set end to text length if rating section not found
        const filteredText = text.substring(start, end);
        setFilteredResumeText(filteredText);

        // Extracting candidate rating
        const ratingStart = text.indexOf(
          "******************************************Candidate Reting:***"
        );
        if (ratingStart !== -1) {
          const filteredText = text.substring(ratingStart);
          setCandidateRating(filteredText);
        }
      } catch (error) {
        console.error("Error fetching resume text:", error);
      }
    };

    fetchResumeText();
  }, [candidateContextTextFile]);

  return (
    <>
      <div>
        <h5 style={{ color: "#9900ff" }}>Evaluation</h5>
        <pre>{candidateRating}</pre>
      </div>
    </>
  );
};

export default withAuthenticator(CompanyCandidateViewProfile);
