import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import {
  listCompanyJobDescriptions,
  listCompanyProfiles,
} from "../../graphql/queries";
import { SESClient, SendEmailCommand } from "@aws-sdk/client-ses";
import JobPostDetails from "./JobPostDetails";
import JobPostForm from "./JobPostForm";
import Header from "../../components/header/Header";
import { Link } from "react-router-dom";
import {
  updateCompanyProfile,
  createPrivateScreening,
  deleteCompanyJobDescription,
} from "../../graphql/mutations";
import BuyPremium from "../../components/company/BuyPremium";
import { useNavigate } from "react-router-dom";

const JobDetailsList = () => {
  const [jobDetails, setJobDetails] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [company, setCompany] = useState({});
  const [companyemail, setCompanyemail] = useState("");
  const [companyID, setCompanyID] = useState("");
  const [nextToken, setNextToken] = useState(null);
  const [companyList, setCompanyList] = useState([]);
  const [companyjobcount, setcompanyjobcount] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const CompanyEmailID = localStorage.getItem("CompanyEmailID");

  const [loading, setLoading] = useState(true);
  const [isSending, setIsSending] = useState(false);

  const [point, setPoint] = useState(0);
  const [reming, setReming] = useState({
    remainingCredit: point,
  });

  const [showPrivateScreeningModal, setShowPrivateScreeningModal] =
    useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const companyProfileID = localStorage.getItem("CompanyProfileID");
    if (companyProfileID) {
      fetchJobDetails(companyProfileID);
    }
  }, []);

  const closeModal = () => {
    console.log("Closing modal");
    setIsModalOpen(false);
  };
  const handleProfileClickOpen = () => {
    setIsProfileModalOpen(true);
  };
  const handleProfileClickClose = () => {
    setIsProfileModalOpen(false);
  };
  useEffect(() => {
    const storedCompanyEmail = localStorage.getItem("CompanyEmailID");
    const storedCompanyID = localStorage.getItem("CompanyProfileID");

    setCompany(storedCompanyEmail);
    setCompanyID(storedCompanyID);

    const fetchData = async () => {
      try {
        const candidateProfileEmailID = localStorage.getItem(
          "candidateProfileEmailID"
        );
        setCompanyemail(candidateProfileEmailID);

        if (!candidateProfileEmailID) {
          // Handle case where candidate email is not found
          return;
        }

        const { data } = await API.graphql(
          graphqlOperation(listCompanyProfiles, {
            filter: {
              email: { eq: candidateProfileEmailID },
            },
            limit: 100, // Limit the number of items per page, adjust as needed
            nextToken: nextToken, // Pass nextToken if available
          })
        );

        if (
          data &&
          data.listCompanyProfiles.items &&
          data.listCompanyProfiles.items.length > 0
        ) {
          const companyData = data.listCompanyProfiles.items[0];
          setCompany(companyData);
          setPoint(companyData.remainingCredit);

          // Save company email and ID to local storage

          // Note: company and companyID won't have the updated values immediately after setCompany/setCompanyID

          const { items, nextToken: newNextToken } = data.listCompanyProfiles;
          setCompanyList((prevList) => [...prevList, ...items]);
          setNextToken(newNextToken);
          setLoading(false);
        } else {
          setLoading(false); // Set loading to false if no data is found
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error
        setLoading(false); // Set loading to false on error
      }
    };

    fetchData();

    // fetching job description count code start

    // const fetchJobCount = async() => {

    //   const storedCompanyID = localStorage.getItem("CompanyProfileID");

    //   // Fetch user profile with pagination
    //   let nextToken = null;
    //   let userProfileList = [];
    //   do {
    //     const joblistResponse  = await API.graphql(
    //       graphqlOperation(listCompanyJobDescriptions, {
    //         filter: { companyProfileID: { eq: storedCompanyID } },
    //         limit: 100,
    //         nextToken: nextToken,
    //       })
    //     );
    //     console.log("####################### from job details list###############3",joblistResponse)
    //     userProfileList.push(
    //       ...joblistResponse.data.listCompanyProfiles.items
    //     );
    //     nextToken = joblistResponse.data.listCompanyProfiles.nextToken;
    //   } while (nextToken);

    //   // Set compnay count
    //   // if (userProfileList.length > 0) {
    //   //   // setUserFullName(userProfileList[0].fullName);
    //   //   setcompanyjobcount(true)

    //   // } else {
    //   //   console.log("User profile not found.");
    //   //   setcompanyjobcount(false)
    //   // }

    // }

    // fetchJobCount()

    const fetchJobCount = async () => {
      const storedCompanyID = localStorage.getItem("CompanyProfileID");

      try {
        let nextToken = null;
        let jobCount = 0;

        do {
          const jobListResponse = await API.graphql(
            graphqlOperation(listCompanyJobDescriptions, {
              filter: { companyProfileID: { eq: storedCompanyID } },
              limit: 100,
              nextToken: nextToken,
            })
          );

          const jobDescriptions =
            jobListResponse.data.listCompanyJobDescriptions.items;
          jobCount += jobDescriptions.length;

          nextToken = jobListResponse.data.listCompanyJobDescriptions.nextToken;
        } while (nextToken);

        // Set company count
        if (jobCount > 0) {
          // You can set company job count here
          setcompanyjobcount(true);
        } else {
          console.log("No job descriptions found for the company.");
          setcompanyjobcount(false);
        }
      } catch (error) {
        console.error("Error fetching job count:", error);
        // Handle error appropriately
      }
    };

    fetchJobCount();

    // fetching job description count code end
  }, [nextToken]);

  console.log("compay count", companyjobcount);

  const fetchJobDetails = async (companyProfileID) => {
    try {
      let nextToken = null;
      const allJobDetails = [];

      // Fetch data with pagination
      do {
        const response = await API.graphql(
          graphqlOperation(listCompanyJobDescriptions, {
            filter: { companyProfileID: { eq: companyProfileID } },
            limit: 100, // Adjust the limit as needed
            nextToken: nextToken,
          })
        );
        const { items, nextToken: newToken } =
          response.data.listCompanyJobDescriptions;
        allJobDetails.push(...items);
        nextToken = newToken;
      } while (nextToken);

      // Format the date fields
      const jobDetailsData = allJobDetails.map((job) => ({
        ...job,
        createdAt: new Date(job.createdAt).toLocaleString(),
        updatedAt: new Date(job.updatedAt).toLocaleString(),
      }));

      setJobDetails(jobDetailsData);
    } catch (error) {
      console.error("Error fetching job details:", error);
    }
  };

  const handleViewDetails = (job) => {
    setSelectedJob(job);
    setShowModal(true);
    setIsEditMode(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedJob(null);
    setIsEditMode(false);
    setShowPrivateScreeningModal(false);
  };

  const handleEditJob = () => {
    setIsEditMode(true);
  };

  const handlePrivateScreeningMail = () => {
    setShowPrivateScreeningModal(true);
  };
  const handlePrivateScreeningMailSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    if (CompanyEmailID !== "hr@varahitechnologies.com" && point < 200) {
        alert("You need at least 200 points to send a mail and Buy Premium.");
        handleCloseModal();
        setIsModalOpen(true); // Open modal if point is less than 200
        console.log(`isModalOpen`, isModalOpen); // Log the state of isModalOpen
        return; // Exit the function
    }

    // If point is 200 or greater, proceed to extract candidate info from the form
    const candidateName = e.target.elements.candidateName.value;
    const candidateEmail = e.target.elements.candidateEmail.value;

    try {
        // Send invitation email
        const emailSent = await sendPrivateScreeningInvitationEmail(candidateName, candidateEmail);

        if (emailSent) {
            // If email sent successfully, create private screening invitation
            await createPrivateScreeningInvitation(candidateName, candidateEmail);

            console.log("Email sent and private screening created successfully");
            e.target.reset(); // Reset the form
        } else {
            console.error("Email sending failed, skipping further steps.");
        }
    } catch (error) {
        console.error("Error creating private screening:", error);
    }
};

  //   const sendPrivateScreeningInvitationEmail = async (
  //     candidateName,
  //     candidateEmail
  //   ) => {
  //     const sesClient = new SESClient({
  //       region: "ap-south-1",
  //       credentials: {
  //         accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  //         secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  //       },
  //       // credentials: {
  //       //   accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  //       //   secretAccessKey: process.env.EACT_APP_AWS_SECRET_ACCESS_KEY,
  //       // },
  //     });

  //     const emailParams = {
  //       Source: "getscreened@varahitechnologies.com",
  //       Destination: {
  //         ToAddresses: [candidateEmail],
  //       },
  //       Message: {
  //         Subject: {
  //           Data: `Congratulations! You've been selected for the screening round for the position of ${selectedJob.jobTitle} at ${company.companyName}.`,
  //         },
  //         Body: {
  //           Text: {
  //             Data: `Dear ${candidateName},

  // Greetings of the Day!
  // I am delighted to inform you that you have been selected for further consideration for the position of ${selectedJob.jobTitle} at ${company.companyName}. Here are some details about the job:

  // Job Title: ${selectedJob.jobTitle}
  // Category: ${selectedJob.selectedCategory}
  // Experience Required: ${selectedJob.selectedExperience}
  // Compensation: ${selectedJob.compensation}
  // Benefits: ${selectedJob.benefits}
  // Skills Required: ${selectedJob.skill}
  // Job Description:
  // ${selectedJob.jobDescription}

  // To streamline the process, we have set up an online portal for the interview. You can access the interview portal using the following link: https://www.getscreened.in. We have also provided a YouTube video for your reference: https://www.youtube.com/watch?si=r94pgS7GIIy-p6j-&v=3rMe3qx9Amc&feature=youtu.be.
  // Please select a suitable time slot from the available options in the portal. If none of the provided time work for you,  we will do our best to accommodate your schedule, and will reach out to you shortly to schedule the interview. Please let us know if you are available for a private screening interview.

  // Best regards,
  //  ${company.contactPersonDesignation}
  //  ${company.contactPersonName}
  //  ${company.contactPersonNumber}
  //  ${company.email}
  //  ${company.companyUrl}`,
  //           },
  //         },
  //       },
  //     };

  //     const sendEmailCommand = new SendEmailCommand(emailParams);
  //     await sesClient.send(sendEmailCommand);
  //     handleCloseModal();
  //   };

  const sendPrivateScreeningInvitationEmail = async (candidateName, candidateEmail) => {
    setIsSending(true);
    const subject = `Congratulations! You've been selected for the screening round for the position of ${selectedJob.jobTitle} at ${company.companyName}.`;
    const body = `
    <p>Dear ${candidateName},</p>
    <p>Greetings of the Day!</p>
    <p>I am delighted to inform you that you have been selected for further consideration for the position of <strong>${selectedJob.jobTitle}</strong> at <strong>${company.companyName}</strong>. Here are some details about the job:</p>
    <ul>
      <li><strong>Job Title:</strong> ${selectedJob.jobTitle}</li>
      <li><strong>Category:</strong> ${selectedJob.selectedCategory}</li>
      <li><strong>Experience Required:</strong> ${selectedJob.selectedExperience}</li>
      <li><strong>Compensation:</strong> ${selectedJob.compensation}</li>
      <li><strong>Benefits:</strong> ${selectedJob.benefits}</li>
      <li><strong>Skills Required:</strong> ${selectedJob.skill}</li>
    </ul>
    <p><strong>Job Description:</strong><br>${selectedJob.jobDescription.replace(/\n/g, '<br>')}</p>
    <p>To streamline the process, we have set up an online portal for the interview. You can access the interview portal using the following link: <a href="https://www.getscreened.in">Interview Portal</a>. We have also provided a YouTube video for your reference: <a href="https://www.youtube.com/watch?si=r94pgS7GIIy-p6j-&v=3rMe3qx9Amc&feature=youtu.be">YouTube Video</a>.</p>
    <p>Please select a suitable time slot from the available options in the portal. If none of the provided times work for you, we will do our best to accommodate your schedule, and will reach out to you shortly to schedule the interview. Please let us know if you are available for a private screening interview.</p>
    <p>Best regards,<br>
    ${company.contactPersonDesignation}<br>
    ${company.contactPersonName}<br>
    ${company.contactPersonNumber}<br>
    <a href="mailto:${company.email}">${company.email}</a><br>
    <a href="${company.companyUrl}">${company.companyUrl}</a></p>
  `;

    const myHeaders = new Headers();
    // myHeaders.append("X-Api-Key", process.env.REACT_APP_API_KEY);
    myHeaders.append("X-Api-Key", "abce-123");
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
        subject: subject,
        body: body,
        to: candidateEmail,
    });

    const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };

    try {
        const response = await fetch("https://pharmaclick.co.in/RazorPay/WeatherForecast/sendEmailSES", requestOptions);
        const result = await response.json(); // Parse the response as JSON

        if (result.message === "Invalid API Key") {
            console.error("Invalid API Key");
            return false;
        }

        if(result.message === "The SMTP server requires a secure connection or the client was not authenticated. The server response was: 5.7.0 Authentication Required. For more information, go to")
        {
          console.log("SMTP Problem")
          return false;
        }

        console.log(result);
        handleCloseModal();
        return true;
    } catch (error) {
        console.error("Error sending email:", error);
        return false;
    } finally {
        setIsSending(false);
    }
};

  const createPrivateScreeningInvitation = async (
    candidateName,
    candidateEmail
  ) => {
    try {
      // Update company point
      const updateSuccess = await updateCompanyPoint();

      if (updateSuccess) {
        // Proceed with creating the private screening

        // Call createPrivateScreening mutation
        const createPrivateScreeningInput = {
          companyProfileID: companyID,
          jobDescription: selectedJob.jobTitle,
          candidateName: candidateName,
          candidateEmail: candidateEmail,
          jdID: selectedJob.id,
          pointsDeducted: 200,
        };

        const createPrivateScreeningResult = await API.graphql(
          graphqlOperation(createPrivateScreening, {
            input: createPrivateScreeningInput,
          })
        );

        if (createPrivateScreeningResult.data.createPrivateScreening) {
          console.log("Private screening created successfully");
        } else {
          console.error("Failed to create private screening");
        }
      } else {
        console.error("Failed to update company point");
      }
    } catch (error) {
      console.error("Error creating private screening:", error);
      throw error; // Rethrow the error to be handled in handlePrivateScreeningMailSubmit
    }
  };
  const updateCompanyPoint = async () => {
    try {
      // Declare variables
      let candidateProfileEmailID, data, companyData, items, newNextToken;

      // Fetch candidate profile email ID from local storage
      candidateProfileEmailID = localStorage.getItem("candidateProfileEmailID");
      setCompanyemail(candidateProfileEmailID);

      // Check if candidate email is available
      if (!candidateProfileEmailID) {
        // Handle case where candidate email is not found
        return false;
      }

      let currentRemainingCredit = point;
      let updatedRemainingCredit = currentRemainingCredit - 200;

      setPoint(updatedRemainingCredit);

      const updateInput = {
        id: company.id, // Assuming you have the company ID available
        remainingCredit: updatedRemainingCredit,
      };

      // Fetch company profiles data
      const result = await API.graphql(
        graphqlOperation(updateCompanyProfile, {
          input: updateInput,
        })
      );

      console.log("GraphQL Result:", result);

      // Destructure data from result
      data = result.data;

      // Check if data is available
      if (data && data.updateCompanyProfile) {
        // Extract company data
        companyData = data.updateCompanyProfile;
        setCompany(companyData);
        setPoint(companyData.remainingCredit);

        console.log("Company Data:", companyData);

        setLoading(false);
        return true; // Return true for success
      } else {
        console.error("No data returned or invalid response structure:", data);
        setLoading(false); // Set loading to false if no data is found
        return false; // Return false for failure
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error
      setLoading(false); // Set loading to false on error
      return false; // Return false for failure
    }
  };
  const handleDeleteJob = async (jobId, jobTitle) => {
    const isConfirmed = window.confirm(
      `Are you sure you want to delete the job titled "${jobTitle}"?`
    );
    if (!isConfirmed) {
      return; // Exit the function if the user cancels the deletion
    }

    try {
      await API.graphql(
        graphqlOperation(deleteCompanyJobDescription, { input: { id: jobId } })
      );
      setJobDetails((prevJobDetails) =>
        prevJobDetails.filter((job) => job.id !== jobId)
      );
      console.log("Job deleted successfully");
    } catch (error) {
      console.error("Error deleting job:", error);
    }
  };

  return (
    <div>
      <Header />
      <div className="container mx-auto px-4 py-8">
        <div className="flex justify-between mb-4 gap-24">
          <h2 className="text-2xl font-bold">Course List</h2>
          {localStorage.getItem("CompanyEmailID") !==
            "hr@varahitechnologies.com" && (
            <h2 className="text-2xl font-bold">Current Credit: {point}</h2>
          )}
          <button
            onClick={() => {
              console.log("Buy Premium button clicked");
              setIsModalOpen(true);
              console.log(`isModalOpen`, isModalOpen);
            }}
            className="border-1 hover:bg-[#9900FF] text-white font-bold py-2 px-4 rounded capitalize"
          >
            Buy Premium
          </button>
          {/* <button
            onClick={handleProfileClickOpen}
            className="border-1 hover:bg-[#9900FF] text-white font-bold py-2 px-4 rounded capitalize"
          >
            Update Profile
          </button> */}
        </div>

        <div className="overflow-x-auto">
          <table className="w-full table-auto">
            <thead className="border-1 text-center">
              <tr className="">
                <th className="px-4 py-2">Course Title</th>
                <th className="px-4 py-2">Created At</th>
                <th className="px-4 py-2">Updated At</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {jobDetails.map((job) => (
                <tr key={job.id} className="border-b">
                  <td className="px-4 py-2">{job.jobTitle}</td>
                  <td className="px-4 py-2">{job.createdAt}</td>
                  <td className="px-4 py-2">{job.updatedAt}</td>
                  <td className="px-4 py-2">
                    <button
                      onClick={() => handleViewDetails(job)}
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                    >
                      View Details
                    </button>
                    <button
                      onClick={() =>
                        navigate("/SendMail", { state: { jobId: job.id } })
                      }
                      className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
                    >
                      Applied Candidates
                    </button>
                    <button
                      onClick={() => handleDeleteJob(job.id, job.jobTitle)}
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Delete Course
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="flex  justify-between mx-32 p-4 gap-6">
            {/* {companyjobcount === true &&
            CompanyEmailID !== "hr@varahitechnologies.com" ? (
              <button
                onClick={() => {
                  console.log("Buy Premium button clicked");
                  setIsModalOpen(true);
                  console.log(`isModalOpen`, isModalOpen);
                }}
                className="border-1 hover:bg-[#9900FF] text-white font-bold py-2 px-4 rounded capitalize"
              >
                Buy Premium
              </button>
            ) : ( */}
            <Link to={"/JobDetails"}>
              <button className="border-1 hover:bg-[#9900FF] text-white font-bold py-2 px-4 rounded capitalize">
                add Course description
              </button>
            </Link>
            {/* )} */}
            <Link to={"/SendMail"}>
              <button className="border-1 hover:bg-[#9900FF] text-white font-bold py-2 px-4 rounded">
                Private candidate
              </button>
            </Link>
          </div>
        </div>
        {isModalOpen && <BuyPremium closeModal={closeModal} />}
        {}
        {showModal && selectedJob && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen">
              <div className="bg-black rounded-lg shadow-lg p-8">
                <button
                  onClick={handleCloseModal}
                  className="absolute top-0 right-0 mt-4 mr-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                >
                  X
                </button>

                {isEditMode ? (
                  <JobPostForm
                    initialValues={selectedJob}
                    onCancel={handleCloseModal}
                  />
                ) : (
                  <>
                    <JobPostDetails jobDetails={selectedJob} />
                    <div className="mt-4 flex justify-between">
                      <button
                        onClick={handleCloseModal}
                        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                      >
                        Close
                      </button>
                      <div>
                        <button
                          onClick={handleEditJob}
                          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        >
                          Edit
                        </button>
                        {/* send Private screeing mail */}
                        <button
                          onClick={handlePrivateScreeningMail}
                          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ml-2"
                        >
                          Private screening mail
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        {showPrivateScreeningModal && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen">
              <div className="bg-white rounded-lg shadow-lg p-8">
                <h2 className="text-2xl font-bold mb-4 text-black">
                  Private Screening Mail
                </h2>
                <form onSubmit={handlePrivateScreeningMailSubmit}>
                  <div className="mb-4">
                    <label
                      htmlFor="candidateName"
                      className="block text-gray-700 font-bold mb-2"
                    >
                      Candidate Name
                    </label>
                    <input
                      type="text"
                      id="candidateName"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <label
                      htmlFor="candidateEmail"
                      className="block text-gray-700 font-bold mb-2"
                    >
                      Candidate Email
                    </label>
                    <input
                      type="email"
                      id="candidateEmail"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      required
                    />
                  </div>
                  {/* <div className="mb-4">
                  <label
                    htmlFor="jobDescription"
                    className="block text-gray-700 font-bold mb-2"
                  >
                    Job Description
                  </label>
                  <textarea
                    id="jobDescription"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    rows="5"
                    required
                  ></textarea>
                </div> */}
                  <div className="flex justify-end">
                    <button
                      type="submit"
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                      disabled={isSending}
                    >
                      {isSending ? "Sending..." : "Send Mail"}
                    </button>
                    <button
                      onClick={handleCloseModal}
                      className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded ml-4"
                    >
                      Close
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobDetailsList;
