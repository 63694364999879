import React, { useState, useEffect } from "react";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { listCompanyProfiles } from "../../graphql/queries";
import Header from "../../components/header/Header";

const CompanyHome = () => {
  const [companyList, setCompanyList] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState({});
  const [companyemail, setCompanyemail] = useState("");
  const [point, setPoint] = useState(0);
  const [companyID, setCompanyID] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");

  // useEffect(() => {

  //   const fetchData = async () => {
  //     try {
  //       const candidateProfileEmailID = localStorage.getItem('candidateProfileEmailID');
  //       setCompanyemail(candidateProfileEmailID);
  //       console.log("Email id from send mail", candidateProfileEmailID)

  //       if (!candidateProfileEmailID) {
  //         // Handle case where candidate email is not found
  //         return;
  //       }

  //       const { data } = await API.graphql(
  //         graphqlOperation(listCompanyProfiles, {
  //           filter: {
  //             email: { eq: candidateProfileEmailID },
  //           },
  //           limit: 100, // Limit the number of items per page, adjust as needed
  //           nextToken: nextToken // Pass nextToken if available
  //         })
  //       );
  //       console.log("**************data************", data)
  //       setCompany(data.listCompanyProfiles.items[0])
  //       setPoint(company.remainingCredit)
  //       setCompanyID(company.companyUrl)
  //       // console.log("direct data from ", data.listCompanyProfiles.items[0])
  //       console.log("*************Comapny*************", company)
  //       console.log("*************company Id*************", companyID)

  //       if (data && data.listCompanyProfiles.items) {
  //         const { items, nextToken } = data.listCompanyProfiles;
  //         setCompanyList(prevList => [...prevList, ...items]); // Merge new items with existing list
  //         setNextToken(nextToken); // Set the nextToken for pagination
  //         setLoading(false); // Set loading to false after data is fetched
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //       // Handle error
  //     }
  //   };

  //   fetchData();

  // }, [nextToken])

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const candidateProfileEmailID = localStorage.getItem('candidateProfileEmailID');
  //       setCompanyemail(candidateProfileEmailID);

  //       if (!candidateProfileEmailID) {
  //         // Handle case where candidate email is not found
  //         return;
  //       }

  //       const { data } = await API.graphql(
  //         graphqlOperation(listCompanyProfiles, {
  //           filter: {
  //             email: { eq: candidateProfileEmailID },
  //           },
  //           limit: 100, // Limit the number of items per page, adjust as needed
  //           nextToken: nextToken // Pass nextToken if available
  //         })
  //       );

  //       if (data && data.listCompanyProfiles.items && data.listCompanyProfiles.items.length > 0) {
  //         const companyData = data.listCompanyProfiles.items[0];
  //         setCompany(companyData);
  //         setPoint(companyData.remainingCredit);
  //         setCompanyID(companyData.id);
  //         setCompanyEmail(companyData.email)
  //         localStorage.setItem("CompanyProfileID", companyID);
  //         localStorage.setItem('myKey', 'myValue');
  //         localStorage.setItem("CompanyProfileID", companyID);
  //         localStorage.setItem("CompanyEmailID", companyEmail);
  //         // Note: company and companyID won't have the updated values immediately after setCompany/setCompanyID

  //         const { items, nextToken: newNextToken } = data.listCompanyProfiles;
  //         setCompanyList(prevList => [...prevList, ...items]);
  //         setNextToken(newNextToken);
  //         setLoading(false);
  //       } else {
  //         setLoading(false); // Set loading to false if no data is found
  //       }
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //       // Handle error
  //       setLoading(false); // Set loading to false on error
  //     }
  //   };

  //   fetchData();
  // }, [nextToken]);

  useEffect(() => {
    // Retrieve company email and ID from local storage
    const storedCompanyEmail = localStorage.getItem("CompanyEmailID");
    const storedCompanyID = localStorage.getItem("CompanyProfileID");

    // console.log("+-+-+-+-++-+-+-+-+-+-+-+-+-+-++-",storedCompanyEmail)
    // console.log("-+-+-+-+-+-+-+-+--+-+-+-+-+-+-+",storedCompanyID)

    // If company email and ID are found in local storage, set them
    if (storedCompanyEmail && storedCompanyID) {
      setCompanyEmail(storedCompanyEmail);
      setCompanyID(storedCompanyID);
    }

    const fetchData = async () => {
      try {
        const candidateProfileEmailID = localStorage.getItem(
          "candidateProfileEmailID"
        );
        setCompanyemail(candidateProfileEmailID);

        if (!candidateProfileEmailID) {
          // Handle case where candidate email is not found
          return;
        }

        const { data } = await API.graphql(
          graphqlOperation(listCompanyProfiles, {
            filter: {
              email: { eq: candidateProfileEmailID },
            },
            limit: 100, // Limit the number of items per page, adjust as needed
            nextToken: nextToken, // Pass nextToken if available
          })
        );

        if (
          data &&
          data.listCompanyProfiles.items &&
          data.listCompanyProfiles.items.length > 0
        ) {
          const companyData = data.listCompanyProfiles.items[0];
          setCompany(companyData);
          setPoint(companyData.remainingCredit);
          setCompanyID(companyData.id);
          setCompanyEmail(companyData.email);

          // Save company email and ID to local storage
          localStorage.setItem("CompanyProfileID", companyData.id);
          localStorage.setItem("CompanyEmailID", companyData.email);
          localStorage.setItem("CompanyProfile", JSON.stringify(companyData));

          // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!Company data!!!!!!!!!!!!!!!!!!!",companyData)
          const storedCompanyData = localStorage.getItem("CompanyProfile");
          const parsedCompanyData = JSON.parse(storedCompanyData);
          // console.log("------------------company data from local----------------------",parsedCompanyData)
          // console.log("----------------------------------------",companyData)
          // console.log("++++++++++++++++++++++++++++++++++++++++",companyData.id)
          // console.log("----------------------------------------",companyData.email)

          // Note: company and companyID won't have the updated values immediately after setCompany/setCompanyID

          const { items, nextToken: newNextToken } = data.listCompanyProfiles;
          setCompanyList((prevList) => [...prevList, ...items]);
          setNextToken(newNextToken);
          setLoading(false);
        } else {
          setLoading(false); // Set loading to false if no data is found
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error
        setLoading(false); // Set loading to false on error
      }
    };

    fetchData();
  }, [nextToken]);

  // console.log("*********company**********", company);
  // console.log("*********company id**********", companyID);
  // console.log("*********company email**********", companyEmail);
  // console.log("*********company**********", company);

  return (
    // original code start
    <>
      <Header />
      <div className="flex flex-col md:flex-row mt-5">
        <div className="md:w-1/2 p-4 ">
          <PrivateScreeningBox />
        </div>
        <div className="md:w-1/2 p-4">
          <CandidateProfileBox />
        </div>
      </div>
    </>
    // original code end
  );
};

// original cose start

const PrivateScreeningBox = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-transparent rounded-lg p-4 flex flex-col items-center border-1 flex-grow h-[650px] justify-between ">
      <div className="flex flex-col gap-3 ">
        <h2 className="text-2xl font-semibold mb-4 text-center">
          Private Screening
        </h2>
        <div className="text-lg font-semibold mb-4">
          <ul className="flex flex-col gap-2 list-disc">
            {/* <li>
              Simply input candidate details, including name, email, and
              interview role, into our intuitive interface.
            </li>
            <li>
              Effortlessly send interview invitations to candidates via email,
              containing all necessary details for the screening interview.
            </li>
            <li>
              Conduct screening interviews using flexible formats, tailored to
              the requirements of the role and the preferences of both parties.
            </li>
            <li>
              Provide feedback and evaluate candidates based on their
              performance during the screening process, aiding in effective
              decision-making.
            </li> */}


            <li>Easy Candidate Input: Simply enter applicant details, such as name, email, and program of interest, into our user-friendly interface.</li>

            <li>Send Invitations: Effortlessly send Screening invitations to applicants via email, with all the necessary details for the Screening process.</li>

            <li>Customizable Screening: Conduct Screening using flexible formats tailored to your university’s specific program requirements and preferences.</li>

            <li>Feedback & Evaluation: Provide detailed feedback and evaluate applicants based on their Screening performance, helping you make well-informed admission decisions.</li>


          </ul>
        </div>
      </div>
      <div className="">
        <button
          onClick={() => navigate("/JobDetailsList")}
          className="border-1 hover:bg-[#9900FF] text-white font-bold py-2 px-4 rounded mt-8"
        >
          Start Screening
        </button>
      </div>
    </div>
  );
};

const CandidateProfileBox = () => {
  return (
    <div className="bg-transparent rounded-lg p-4 flex flex-col items-center border-1 flex-grow h-[650px] justify-between ">
      <div className="flex flex-col gap-3">
        <h2 className="text-2xl font-semibold mb-4 text-center">
          {/* Future Search: Simplifying Recruitment */}
          Future Search: Simplifying University Admissions
        </h2>
        {/* <h2 className="text-2xl font-semibold mb-4">Effortlessly manage your recruitment process with Future Search.</h2> */}
        <div className="text-lg font-semibold mb-4">
          <ul className="flex flex-col gap-2 list-disc">
            {/* <li>
              Effortlessly manage your recruitment process with Future Search.
            </li>
            <li>
              Free Search: Use ChatGPT-like capabilities to search and explore
              candidate data, making the process more efficient.
            </li>
            <li>
              Personalized Search: Leverage context-sensitive search, tailored
              to each candidate's profile, for a more personalized experience.
            </li>
            <li>
              Effortless Shortlisting: Quickly shortlist candidates based on
              their performance and relevance, aiding in effective
              decision-making.
            </li>
            <li>
              Simplify and optimize your hiring process with Future Search!
            </li> */}


            <li>Efficient Admissions Management: Streamline your university’s admissions process with Future Search.</li>

            <li>Free Search: Use AI-powered search capabilities to quickly explore applicant profiles and data, making the process faster and more efficient.</li>

            <li>Personalized Search: Customize your search based on specific program needs, offering a tailored experience for each applicant's qualifications and fit.</li>

            <li>Effortless Shortlisting: Quickly shortlist candidates based on their performance and program relevance, simplifying decision-making for your admissions team.</li>

            <li>Optimize Your Process: Future Search helps simplify and optimize your admissions workflow, ensuring a smoother experience for both applicants and university staff.</li>


          </ul>
        </div>
      </div>

      <div>
        <button
          disabled
          className="border-1 hover:bg-[#9900FF] text-white font-bold py-2 px-4 rounded"
        >
          Coming soon
        </button>
      </div>
    </div>
  );
};

// original code end

export default CompanyHome;
