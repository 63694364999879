import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API, graphqlOperation, Storage, Auth } from "aws-amplify";
import { getCandidateProfile } from "../graphql/queries";
import awsExports from "../aws-exports";
// import { RiseLoader } from "react-spinners";
import Header from "../components/header/Header";
import { updateCandidateProfile } from "../graphql/mutations";
import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';


API.configure(awsExports);
Auth.configure(awsExports);
Storage.configure(awsExports);

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const EditCandidateProfileReg = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [candidateProfileId, setCandidateProfileId] = useState(
    localStorage.getItem("candidateProfileId") || ""
  );

  // original code start

  const [candidateProfile, setCandidateProfile] = useState({
    fullName: "",
    gender: "",
    mobileNumber: "",
    description: "",
    matchedPercentage: 0,
    workStatus: "",
    currentCity: "",
    educationDetails: "",
    keySkills: "",
    workPreference: "",
    preferredSalary: "",
    photoURL: "",
    resumeURL: "",
    roleApplyFor: "", // Add roleApplyFor field
    address: "",
    // workMode: "",
    // workCategory: "",
    workMode: [],       // Initialize as an empty array
    workCategory: [],   // Initialize as an empty array
    githubURL: "", // Added githubURL field
    linkedinURL: "", // Added linkedinURL field
  });
  console.log("88888888888888888888888888888888888888888888888", candidateProfile)
  useEffect(() => {
    if (candidateProfileId) {
      const fetchCandidateProfile = async () => {
        setLoading(true);
        try {
          const response = await API.graphql(
            graphqlOperation(getCandidateProfile, {
              id: candidateProfileId,
            })
          );
          const profileData = response.data.getCandidateProfile;
          setCandidateProfile(profileData);
          console.log("99999999999999999999999999999999999999999", profileData)
        } catch (error) {
          console.error("Error fetching candidate profile:", error);

        }
        setLoading(false);
      };
      fetchCandidateProfile();
    }
  }, [candidateProfileId]);

  // const handleChange = (e) => {
  //   const { name, value, files } = e.target;
  //   if (files) {
  //     setCandidateProfile((prevState) => ({ ...prevState, [name]: files[0] }));
  //   } else {
  //     setCandidateProfile((prevState) => ({ ...prevState, [name]: value }));
  //   }
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   const yourS3BucketName = "getscreenedstoragebucket71511-staging";

  //   try {
  //     // File upload handling
  //     let updatedProfile = { ...candidateProfile };

  //     if (candidateProfile.photo) {
  //       console.log("Uploading photo:", candidateProfile.photo);
  //       const photoKey = await Storage.put(
  //         `${Date.now()}_${candidateProfile.photo.name}`,
  //         candidateProfile.photo,
  //         { contentType: "image/*" }
  //       );
  //       updatedProfile.photoURL = `https://${yourS3BucketName}.s3.amazonaws.com/public/${photoKey.key}`;
  //       console.log("Photo URL after upload:", updatedProfile.photoURL);
  //     }

  //     if (candidateProfile.resume) {
  //       console.log("Uploading resume:", candidateProfile.resume);
  //       const resumeKey = await Storage.put(
  //         `${Date.now()}_${candidateProfile.resume.name}`,
  //         candidateProfile.resume,
  //         { contentType: "application/pdf" }
  //       );
  //       updatedProfile.resumeURL = `https://${yourS3BucketName}.s3.amazonaws.com/public/${resumeKey.key}`;
  //       console.log("Resume URL after upload:", updatedProfile.resumeURL);
  //     }

  //     const updateInput = {
  //       id: candidateProfile.id,
  //       fullName: candidateProfile.fullName,
  //       gender: candidateProfile.gender,
  //       mobileNumber: candidateProfile.mobileNumber,
  //       description: candidateProfile.description,
  //       matchedPercentage: candidateProfile.matchedPercentage,
  //       workStatus: candidateProfile.workStatus,
  //       currentCity: candidateProfile.currentCity,
  //       educationDetails: candidateProfile.educationDetails,
  //       keySkills: candidateProfile.keySkills,
  //       workPreference: candidateProfile.workPreference,
  //       preferredSalary: candidateProfile.preferredSalary,
  //       roleApplyFor: candidateProfile.roleApplyFor,
  //       address: candidateProfile.address,
  //       photoURL: updatedProfile.photoURL || candidateProfile.photoURL,
  //       resumeURL: updatedProfile.resumeURL || candidateProfile.resumeURL,
  //     };

  //     // Check if required fields are filled
  //     if (
  //       !updateInput.fullName ||
  //       !updateInput.mobileNumber ||
  //       !updateInput.workStatus ||
  //       !updateInput.roleApplyFor ||
  //       !updateInput.address
  //     ) {
  //       alert("Please fill in all required fields.");
  //       setLoading(false);
  //       return;
  //     }

  //     // Mutation request to update the candidate profile
  //     await API.graphql(
  //       graphqlOperation(updateCandidateProfile, { input: updateInput })
  //     );

  //     // Log success message
  //     console.log("Profile updated successfully");

  //     // Display updated candidate profile information
  //     console.log("Updated Candidate Profile:", updateInput);

  //     // Navigate or perform any other action after successful update
  //     navigate("/");
  //   } catch (error) {
  //     // Error handling
  //     console.error("Error updating candidate profile:", error);
  //   } finally {
  //     // Reset loading state
  //     setLoading(false);
  //   }
  // };

  // original code end 

  // formik code start



  const signupschema = Yup.object({
    fullName: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Full name must contain only letters")
      .min(2, "Please Enter vaild Name")
      .max(25, "Full name must not exceed 25 characters"),
    // .required("Please Enter Full name"),
    mobileNumber: Yup.string()
      .matches(/^[0-9]{10}$/, 'Mobile Number must be exactly 10 digits'),
    // .required('Mobile Number is required'),


    workStatus: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "must contain only letters"),
    // .required('Work Status is required'),

    roleApplyFor: Yup.string()
    .matches(/^[A-Za-z.,\s\-]+$/, 'Only letters, spaces, periods, commas, and hyphens are allowed'),


    workPreference: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "must contain only letters"),
    currentCity: Yup.string()
      // .required('city is required')
      .matches(/^[A-Za-z\s]+$/, " must contain only letters"),

    // keySkills: Yup.string()
    //   // .required('Skills must be there')
    //   .matches(/^[a-zA-Z\s,]+$/, 'Only letters are allowed'),
    keySkills: Yup.string()
    .required('Skills must be there')
    .matches(/^[a-zA-Z\s,.\\-]+$/, 'Only letters, spaces, commas, periods, and hyphens are allowed'),

    preferredSalary: Yup.string()
      .matches(/^[0-9]+$/, 'Valid salary'),
    // .required('Field is required'),

    description: Yup.string()
      // .required('Description must be there')
      .matches(/^[a-zA-Z0-9\s,.']+$/, 'Only letters and numbers are allowed'),

    resume: Yup.mixed()
      // .required('pdf is required')
      .test('fileType', 'Only PDF files are allowed', (value) =>

        value ? value.type === 'application/pdf' : true
        // console.log("[][][][][][]][][][]][][][",value)
      )
      .test('fileSize', 'File size should be between 5 KB and 25 MB', (value) =>
        value ? value.size >= 5 * 1024 && value.size <= 5 * 1024 * 1024 : true
      ),

    photo: Yup.mixed()
      .test('fileType', 'Invalid file format', (value) => {
        if (!value) return true; // Allow empty values (no file selected)
        // console.log("image information--------------------------------",value)
        return value && value.type && value.type.split('/')[0] === 'image'; // Check if the file type is an image
      })
      .test('fileSize', 'File size should be between 5 KB and 25 MB', (value) =>
        value ? value.size >= 5 * 1024 && value.size <= 25 * 1024 * 1024 : true
      ),

    // workMode: Yup.array().min(1, 'Please select at least one work mode').required('Work Mode is required'),
    // workCategory: Yup.array().min(1, 'Please select at least one work category').required('Work Category is required'),

    // workMode: Yup.array().min(1, 'Please select at least one work mode'),
    // workCategory: Yup.array().min(1, 'Please select at least one work category'),


  })





  return (
    // original code start

    // <div className="container-fluid">
    //   <div className="row">
    //     <div className="col py-3">
    //       <Header />
    //       <h2 className="text-2xl md:text-3xl font-bold mb-6 text-center mt-8">
    //         Edit Candidate Profile
    //       </h2>
    //       {loading ? (
    //         <div className="flex justify-center items-center">
    //           {/* <RiseLoader
    //             color={"#ffffff"}
    //             loading={loading}
    //             css={override}
    //             size={40}
    //             aria-label="Loading Spinner"
    //           /> */}
    //         </div>
    //       ) : (
    //         <form
    //           onSubmit={handleSubmit}
    //           className="grid grid-cols-1 md:grid-cols-2 gap-6"
    //         >
    //           <div>
    //             <label htmlFor="fullName" className="block text-sm font-medium">
    //               Full Name *
    //             </label>

    //             <input
    //               type="text"
    //               name="fullName"
    //               // placeholder="Atul"
    //               placeholder={candidateProfile.fullName || "Enter name"}
    //               value={values.fullName}
    //               onChange={handleChange}
    //               className="input input-bordered w-full p-2 rounded"
    //               style={{
    //                 backgroundColor: "transparent",
    //                 border: "1px solid #fff",
    //               }}
    //             />
    //           </div>

    //           <div>
    //             <label htmlFor="gender" className="block text-sm font-medium">
    //               Gender
    //             </label>
    //             <select
    //               name="gender"
    //               onChange={handleChange}
    //               value={values.gender}
    //               className="select select-bordered w-full p-2 rounded"
    //               style={{
    //                 backgroundColor: "transparent",
    //                 border: "1px solid #fff",
    //               }}
    //             >
    //               <option disabled selected style={{ color: "#000" }}>
    //                 Gender
    //               </option>
    //               <option value="male" style={{ color: "#000" }}>
    //                 Male
    //               </option>
    //               <option value="female" style={{ color: "#000" }}>
    //                 Female
    //               </option>
    //               <option value="other" style={{ color: "#000" }}>
    //                 Other
    //               </option>
    //             </select>
    //           </div>
    //           <div>
    //             <label
    //               htmlFor="mobileNumber"
    //               className="block text-sm font-medium"
    //             >
    //               Mobile Number *
    //             </label>
    //             <input
    //               type="text"
    //               name="mobileNumber"
    //               placeholder="e.g. 9561471059"
    //               value={values.mobileNumber}
    //               onChange={handleChange}
    //               className="input input-bordered w-full p-2 rounded"
    //               style={{
    //                 backgroundColor: "transparent",
    //                 border: "1px solid #fff",
    //               }}
    //             />
    //           </div>
    //           <div>
    //             <label
    //               htmlFor="description"
    //               className="block text-sm font-medium"
    //             >
    //               Description
    //             </label>
    //             <textarea
    //               name="description"
    //               id="description"
    //               placeholder="Brief description about yourself"
    //               value={values.description}
    //               onChange={handleChange}
    //               className="mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
    //               style={{
    //                 backgroundColor: "transparent",
    //                 border: "1px solid #fff",
    //               }}
    //             ></textarea>
    //           </div>
    //           <div>
    //             <label
    //               htmlFor="workStatus"
    //               className="block text-sm font-medium"
    //             >
    //               Work Status *
    //             </label>
    //             <input
    //               type="text"
    //               name="workStatus"
    //               placeholder="e.g. fresher or experience"
    //               value={values.workStatus}
    //               onChange={handleChange}
    //               className="input input-bordered w-full p-2 rounded"
    //               style={{
    //                 backgroundColor: "transparent",
    //                 border: "1px solid #fff",
    //               }}
    //             />
    //           </div>
    //           <div>
    //             <label
    //               htmlFor="currentCity"
    //               className="block text-sm font-medium"
    //             >
    //               Current City
    //             </label>
    //             <input
    //               type="text"
    //               name="currentCity"
    //               placeholder="e.g. Pune"
    //               value={values.currentCity}
    //               onChange={handleChange}
    //               className="input input-bordered w-full p-2 rounded"
    //               style={{
    //                 backgroundColor: "transparent",
    //                 border: "1px solid #fff",
    //               }}
    //             />
    //           </div>
    //           <div>
    //             <label
    //               htmlFor="educationDetails"
    //               className="block text-sm font-medium"
    //             >
    //               Education Details
    //             </label>
    //             <textarea
    //               name="educationDetails"
    //               placeholder="e.g. give the highest qualification"
    //               value={values.educationDetails}
    //               onChange={handleChange}
    //               className="textarea textarea-bordered w-full p-2 rounded h-24 md:col-span-2"
    //               style={{
    //                 backgroundColor: "transparent",
    //                 border: "1px solid #fff",
    //               }}
    //             ></textarea>
    //           </div>
    //           <div>
    //             <label
    //               htmlFor="keySkills"
    //               className="block text-sm font-medium"
    //             >
    //               Key Skills
    //             </label>
    //             <input
    //               type="text"
    //               name="keySkills"
    //               placeholder="separated by comma e.g. (ReactJS, NextJS)"
    //               value={values.keySkills}
    //               onChange={handleChange}
    //               className="input input-bordered w-full p-2 rounded"
    //               style={{
    //                 backgroundColor: "transparent",
    //                 border: "1px solid #fff",
    //               }}
    //             />
    //           </div>
    //           <div>
    //             <label
    //               htmlFor="workPreference"
    //               className="block text-sm font-medium"
    //             >
    //               Work Preference
    //             </label>
    //             <input
    //               type="text"
    //               name="workPreference"
    //               placeholder="prefer choice to work location e.g .(Pune)"
    //               value={values.workPreference}
    //               onChange={handleChange}
    //               className="input input-bordered w-full p-2 rounded"
    //               style={{
    //                 backgroundColor: "transparent",
    //                 border: "1px solid #fff",
    //               }}
    //             />
    //           </div>
    //           <div>
    //             <label
    //               htmlFor="preferredSalary"
    //               className="block text-sm font-medium"
    //             >
    //               Preferred Salary
    //             </label>
    //             <input
    //               type="text"
    //               name="preferredSalary"
    //               placeholder="expected salary e.g. (500000)"
    //               value={values.preferredSalary}
    //               onChange={handleChange}
    //               className="input input-bordered w-full p-2 rounded"
    //               style={{
    //                 backgroundColor: "transparent",
    //                 border: "1px solid #fff",
    //               }}
    //             />
    //           </div>
    //           <div>
    //             <label
    //               htmlFor="roleApplyFor"
    //               className="block text-sm font-medium"
    //             >
    //               Role Apply For *
    //             </label>
    //             <input
    //               type="text"
    //               name="roleApplyFor"
    //               placeholder="eg. junior / senior data scientist"
    //               value={values.roleApplyFor}
    //               onChange={handleChange}
    //               className="input input-bordered w-full p-2 rounded"
    //               style={{
    //                 backgroundColor: "transparent",
    //                 border: "1px solid #fff",
    //               }}
    //             />
    //           </div>
    //           <div>
    //             <label htmlFor="address" className="block text-sm font-medium">
    //               Address *
    //             </label>
    //             <input
    //               type="text"
    //               name="address"
    //               placeholder="e.g. hinjewadi phase 1 wakad pune maharashtra"
    //               value={values.address}
    //               onChange={handleChange}
    //               className="input input-bordered w-full p-2 rounded"
    //               style={{
    //                 backgroundColor: "transparent",
    //                 border: "1px solid #fff",
    //               }}
    //             />
    //           </div>
    //           <div>
    //             <label htmlFor="photo" className="block text-sm font-medium">
    //               Candidate Photo :{" "}
    //               {candidateProfile.photoURL
    //                 ? candidateProfile.photoURL.split("/").pop()
    //                 : "Required to add photo"}
    //             </label>

    //             <input
    //               type="file"
    //               name="photo"
    //               id="photo"
    //               accept="image/*"
    //               onChange={handleChange}
    //               className="mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
    //               style={{
    //                 backgroundColor: "transparent",
    //                 border: "1px solid #fff",
    //               }}
    //             />
    //           </div>
    //           <div>
    //             <label htmlFor="resume" className="block text-sm font-medium">
    //               Resume only (.pdf) *:{" "}
    //               {candidateProfile.resumeURL
    //                 ? candidateProfile.resumeURL.split("/").pop()
    //                 : "Required to add resume"}
    //             </label>

    //             <input
    //               type="file"
    //               name="resume"
    //               id="resume"
    //               accept=".pdf"
    //               onChange={handleChange}
    //               className="mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
    //               style={{
    //                 backgroundColor: "transparent",
    //                 border: "1px solid #fff",
    //               }}
    //             />
    //           </div>
    //           <div>{/* Include other input fields similarly */}</div>
    //           <div className="md:col-span-2 flex justify-end">
    //             <button
    //               type="cancel"
    //               className="w-full md:w-auto  hover:bg-blue-700 text-white font-medium py-2 px-4 mr-4 rounded"
    //               style={{
    //                 backgroundColor: "transperant",
    //                 border: "1px solid #9900ff",
    //                 color: "#9900ff",
    //               }}
    //               onClick={() => navigate("/CandidateDashboard")}
    //             >
    //               Cancel
    //             </button>
    //             <button
    //               type="submit"
    //               className="w-full md:w-auto  hover:bg-blue-700  font-medium py-2 px-4 rounded"
    //               style={{
    //                 backgroundColor: "#9900ff",
    //                 border: "1px solid #9900ff",
    //                 color: "#fff",
    //               }}
    //             >
    //               Submit
    //             </button>
    //           </div>
    //         </form>
    //       )}
    //     </div>
    //   </div>
    // </div>

    // original code end


    // formik 1 start
    <div className="container-fluid">
      <div className="row">
        <div className="col py-3">
          <Header />
          <h2 className="text-2xl md:text-3xl font-bold mb-6 text-center mt-8">
            Edit Candidate Profile
          </h2>
          {loading ? (
            <div className="flex justify-center items-center">
              {/* <RiseLoader
              color={"#ffffff"}
              loading={loading}
              css={override}
              size={40}
              aria-label="Loading Spinner"
            /> */}
            </div>
          ) : (
            <Formik
              initialValues={candidateProfile}
              validationSchema={signupschema}
              onSubmit={async (values) => {
                setLoading(true);
                const yourS3BucketName = "getscreenedstoragebucket71511-staging";

                try {
                  // File upload handling
                  let updatedProfile = { ...candidateProfile };

                  if (values.photo) {
                    console.log("Uploading photo:", values.photo);
                    const photoKey = await Storage.put(
                      `${Date.now()}_${values.photo.name}`,
                      values.photo,
                      { contentType: "image/*" }
                    );
                    updatedProfile.photoURL = `https://${yourS3BucketName}.s3.amazonaws.com/public/${photoKey.key}`;
                    console.log("Photo URL after upload:", updatedProfile.photoURL);
                  }

                  if (values.resume) {
                    console.log("Uploading resume:", values.resume);
                    const resumeKey = await Storage.put(
                      `${Date.now()}_${values.resume.name}`,
                      values.resume,
                      { contentType: "application/pdf" }
                    );
                    updatedProfile.resumeURL = `https://${yourS3BucketName}.s3.amazonaws.com/public/${resumeKey.key}`;
                    console.log("Resume URL after upload:", updatedProfile.resumeURL);
                  }

                  const mutationInput = {
                    id: candidateProfile.id,
                    fullName: values.fullName,
                    gender: values.gender,
                    mobileNumber: values.mobileNumber,
                    description: values.description,
                    matchedPercentage: values.matchedPercentage,
                    workStatus: values.workStatus,
                    currentCity: values.currentCity,
                    educationDetails: values.educationDetails,
                    keySkills: values.keySkills,
                    workPreference: values.workPreference,
                    preferredSalary: values.preferredSalary,
                    roleApplyFor: values.roleApplyFor,
                    address: values.address,
                    photoURL: updatedProfile.photoURL || values.photoURL,
                    resumeURL: updatedProfile.resumeURL || values.resumeURL,
                    workMode: values.workMode,
                    workCategory: values.workCategory,
                    githubURL: values.githubURL, // Added githubURL field
                    linkedinURL: values.linkedinURL, // Added linkedinURL field
                  };

                  // Mutation request to update the candidate profile
                  await API.graphql(
                    graphqlOperation(updateCandidateProfile, { input: mutationInput })
                  );

                  // Log success message
                  console.log("Profile updated successfully");

                  // Navigate or perform any other action after successful update
                  navigate("/");
                } catch (error) {
                  // Error handling
                  console.error("Error updating candidate profile:", error);
                } finally {
                  // Reset loading state
                  setLoading(false);
                }
              }}
            >
              {({ values, errors, touched, handleChange, handleSubmit, setFieldValue, handleBlur }) => (
                <form
                  onSubmit={handleSubmit}
                  className="grid grid-cols-1 md:grid-cols-2 gap-6"
                >
                  <div>
                    <label htmlFor="fullName" className="block text-sm font-medium">
                      Full Name *
                    </label>
                    <Field
                      type="text"
                      name="fullName"
                      className="input input-bordered w-full p-2 rounded"
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid #fff",
                      }}
                    />
                    {/* <ErrorMessage name="fullName" component="div" /> */}
                    {touched.fullName && errors.fullName ? (<p className='text-sm text-red-700'>{errors.fullName}</p>) : (null)}
                  </div>
                  {
                    console.log("++++++++++++++++++++++++++++++++++++++", errors)
                  }
                  <div>
                    <label htmlFor="gender" className="block text-sm font-medium ">
                      Gender
                    </label>
                    <select
                      name="gender"
                      onChange={handleChange}
                      value={values.gender}
                      className="select select-bordered w-full p-2 rounded bg-transparent border-1"
                    >
                      <option disabled selected style={{ color: "#000" }}>
                        Gender
                      </option>
                      <option value="male" style={{ color: "#000" }}>
                        Male
                      </option>
                      <option value="female" style={{ color: "#000" }}>
                        Female
                      </option>
                      <option value="other" style={{ color: "#000" }}>
                        Other
                      </option>
                    </select>
                  </div>

                  <div>
                    <label htmlFor="mobileNumber" className="block text-sm font-medium">
                      Mobile Number *
                    </label>
                    <input
                      type="text"
                      name="mobileNumber"
                      placeholder="e.g. 9561471059"
                      value={values.mobileNumber}
                      onChange={handleChange}
                      className="input input-bordered w-full p-2 rounded bg-transparent border-1"
                    />
                    {touched.mobileNumber && errors.mobileNumber ? (<p className='text-sm text-red-700'>{errors.mobileNumber}</p>) : (null)}
                  </div>

                  <div>
                    <label htmlFor="description" className="block text-sm font-medium">
                      Description
                    </label>
                    <textarea
                      name="description"
                      placeholder="Brief description about yourself"
                      value={values.description}
                      onChange={handleChange}
                      className="textarea textarea-bordered w-full p-2 rounded bg-transparent border-1"
                    ></textarea>
                    {touched.description && errors.description ? (<p className='text-sm text-red-700'>{errors.description}</p>) : (null)}
                  </div>

                  <div>
                    <label htmlFor="workStatus" className="block text-sm font-medium">
                      Work Status *
                    </label>
                    <input
                      type="text"
                      name="workStatus"
                      placeholder="e.g. fresher or experience"
                      value={values.workStatus}
                      onChange={handleChange}
                      className="input input-bordered w-full p-2 rounded bg-transparent border-1"
                    />
                    {touched.workStatus && errors.workStatus ? (<p className='text-sm text-red-700'>{errors.workStatus}</p>) : (null)}
                  </div>

                  <div>
                    <label htmlFor="currentCity" className="block text-sm font-medium">
                      Current City
                    </label>
                    <input
                      type="text"
                      name="currentCity"
                      placeholder="e.g. Pune"
                      value={values.currentCity}
                      onChange={handleChange}
                      className="input input-bordered w-full p-2 rounded bg-transparent border-1"
                    />
                    {touched.currentCity && errors.currentCity ? (<p className='text-sm text-red-700'>{errors.currentCity}</p>) : (null)}
                  </div>

                  <div>
                    <label htmlFor="educationDetails" className="block text-sm font-medium">
                      Education Details
                    </label>
                    <textarea
                      name="educationDetails"
                      placeholder="e.g. give the highest qualification"
                      value={values.educationDetails}
                      onChange={handleChange}
                      className="textarea textarea-bordered w-full p-2 rounded bg-transparent border-1"
                    ></textarea>
                    {touched.educationDetails && errors.educationDetails ? (<p className='text-sm text-red-700'>{errors.educationDetails}</p>) : (null)}
                  </div>

                  <div>
                    <label htmlFor="keySkills" className="block text-sm font-medium">
                      Key Skills
                    </label>
                    <input
                      type="text"
                      name="keySkills"
                      placeholder="separated by comma e.g. (ReactJS, NextJS)"
                      value={values.keySkills}
                      onChange={handleChange}
                      className="input input-bordered w-full p-2 rounded bg-transparent border-1"
                    />
                    {touched.keySkills && errors.keySkills ? (<p className='text-sm text-red-700'>{errors.keySkills}</p>) : (null)}
                  </div>

                  <div>
                    <label htmlFor="workPreference" className="block text-sm font-medium">
                      Work Preference
                    </label>
                    <input
                      type="text"
                      name="workPreference"
                      placeholder="prefer choice to work location e.g .(Pune)"
                      value={values.workPreference}
                      onChange={handleChange}
                      className="input input-bordered w-full p-2 rounded bg-transparent border-1"
                    />
                    {touched.workPreference && errors.workPreference ? (<p className='text-sm text-red-700'>{errors.workPreference}</p>) : (null)}
                  </div>

                  <div>
                    <label htmlFor="preferredSalary" className="block text-sm font-medium">
                      Preferred Salary
                    </label>
                    <input
                      type="text"
                      name="preferredSalary"
                      placeholder="expected salary e.g. (500000)"
                      value={values.preferredSalary}
                      onChange={handleChange}
                      className="input input-bordered w-full p-2 rounded bg-transparent border-1"
                    />
                    {touched.preferredSalary && errors.preferredSalary ? (<p className='text-sm text-red-700'>{errors.preferredSalary}</p>) : (null)}
                  </div>

                  <div>
                    <label htmlFor="roleApplyFor" className="block text-sm font-medium">
                      Role Apply For *
                    </label>
                    <input
                      type="text"
                      name="roleApplyFor"
                      placeholder="eg. junior / senior data scientist"
                      value={values.roleApplyFor}
                      onChange={handleChange}
                      className="input input-bordered w-full p-2 rounded bg-transparent border-1"
                    />
                    {touched.roleApplyFor && errors.roleApplyFor ? (<p className='text-sm text-red-700'>{errors.roleApplyFor}</p>) : (null)}
                  </div>

                  <div>
                    <label htmlFor="address" className="block text-sm font-medium">
                      Address
                    </label>
                    <input
                      type="text"
                      name="address"
                      placeholder="e.g. hinjewadi phase 1 wakad pune maharashtra"
                      value={values.address}
                      onChange={handleChange}
                      className="input input-bordered w-full p-2 rounded bg-transparent border-1"
                    />
                    {touched.address && errors.address ? (<p className='text-sm text-red-700'>{errors.address}</p>) : (null)}
                  </div>
                  {/* original Code Start */}

                  {/* <div>
                    <label htmlFor="workMode" className="block text-sm font-medium">
                      Work Mode
                    </label>
                    <select
                      name="workMode"
                      value={values.workMode}
                      onChange={handleChange}
                      className="select select-bordered w-full p-2 rounded bg-gray-700"
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid #fff",
                      }}
                    >
                      <option value="" className="text-black">Select Work Mode</option>
                      <option value="remote" className="text-black">Remote</option>
                      <option value="hybrid" className="text-black">Hybrid</option>
                      <option value="Work From office" className="text-black">Work from Office</option>
                    </select>
                    {errors.workMode && touched.workMode ? (
                      <p className="text-sm text-red-700">{errors.workMode}</p>
                    ) : null}
                  </div>
                  <div>
                    <label htmlFor="workCategory" className="block text-sm font-medium">
                      Work Category *
                    </label>
                    <select
                      name="workCategory"
                      value={values.workCategory}
                      onChange={handleChange}
                      className="select select-bordered w-full p-2 rounded"
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid #fff",
                      }}
                    >
                      <option value="" className="text-black">Select Work Category</option>
                      <option value="internship" className="text-black">Internship</option>
                      <option value="fulltime" className="text-black">Full Time</option>
                      <option value="parttime" className="text-black">Part Time</option>
                    </select>
                    {errors.workCategory && touched.workCategory ? (
                      <p className="text-sm text-red-700">{errors.workCategory}</p>
                    ) : null}
                  </div> */}

                  {/* original Code End */}

                  {/* testing code start */}

                  {/* <div className="">
                <label className="block text-sm font-medium ">Work Mode *</label>
                <div className="flex flex-wrap p-2 border border-white rounded-md">
                  {['Remote', 'Hybrid', 'Office'].map((mode) => (
                    <div key={mode} className="flex items-center mr-4 mb-2">
                      <input
                        type="checkbox"
                        id={mode}
                        name="workMode"
                        value={mode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.workMode.includes(mode)}
                        className="mr-2"
                      />
                      <label htmlFor={mode} className="text-sm ">{mode}</label>
                    </div>
                  ))}
                </div>
                {touched.workMode && errors.workMode ? (
                  <p className="text-sm text-red-700">{errors.workMode}</p>
                ) : null}
              </div> */}

                  {/* <div>
                <label className="block text-sm font-medium">Work Category *</label>
                <div className="flex flex-wrap p-2 border border-white rounded-md ">
                  {['Internship', 'FullTime', 'PartTime'].map((category) => (
                    <div key={category} className="flex items-center mr-4 mb-2">
                      <input
                        type="checkbox"
                        id={category}
                        name="workCategory"
                        value={category}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.workCategory.includes(category)}
                        className="mr-2"
                      />
                      <label htmlFor={category} className="text-sm ">{category}</label>
                    </div>
                  ))}
                </div>
                {touched.workCategory && errors.workCategory ? (
                  <p className="text-sm text-red-700">{errors.workCategory}</p>
                ) : null}
              </div> */}

                  <div className="">
                    <label className="block text-sm font-medium">Work Mode *</label>
                    <div className="flex flex-wrap p-2 border border-white rounded-md">
                      {['Remote', 'Hybrid', 'Office'].map((mode) => (
                        <div key={mode} className="flex items-center mr-4 mb-2">
                          <input
                            type="checkbox"
                            id={mode}
                            name="workMode"
                            value={mode}
                            onChange={handleChange}
                            // onChange={(e) => {
                            //   const isChecked = e.target.checked;
                            //   setFieldValue(
                            //     "workMode",
                            //     isChecked
                            //       ? [...values.workMode, mode]
                            //       : values.workMode.filter((item) => item !== mode)
                            //   );
                            // }}
                            onBlur={handleBlur}
                            // checked={values.workMode.includes(mode)}
                            checked={values.workMode && values.workMode.includes(mode)}
                            className="mr-2"
                          />
                          <label htmlFor={mode} className="text-sm ">{mode}</label>
                        </div>
                      ))}
                    </div>
                    {touched.workMode && errors.workMode ? (
                      <p className="text-sm text-red-700">{errors.workMode}</p>
                    ) : null}
                  </div>

                  <div>
                    <label className="block text-sm font-medium">Work Category *</label>
                    <div className="flex flex-wrap p-2 border border-white rounded-md ">
                      {['Internship', 'FullTime', 'PartTime'].map((category) => (
                        <div key={category} className="flex items-center mr-4 mb-2">
                          <input
                            type="checkbox"
                            id={category}
                            name="workCategory"
                            value={category}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            // checked={values.workCategory.includes(category)}
                            checked={values.workCategory && values.workCategory.includes(category)}
                            className="mr-2"
                          />
                          <label htmlFor={category} className="text-sm ">{category}</label>
                        </div>
                      ))}
                    </div>
                    {touched.workCategory && errors.workCategory ? (
                      <p className="text-sm text-red-700">{errors.workCategory}</p>
                    ) : null}
                  </div>



                  {/* testing code ens */}

                  {/*  linedin, github*/}

                  <div>
                    <label htmlFor="githubURL" className="block text-sm font-medium">
                      GitHub URL
                    </label>
                    <input
                      type="text"
                      name="githubURL"
                      // placeholder="https://github.com/username"
                      value={values.githubURL}
                      onChange={handleChange}
                      className="input input-bordered w-full p-2 rounded bg-transparent border-1"
                    />
                    {touched.githubURL && errors.githubURL && (
                      <p className="text-sm text-red-700">{errors.githubURL}</p>
                    )}
                  </div>
                  <div>
                    <label htmlFor="linkedinURL" className="block text-sm font-medium">
                      LinkedIn URL
                    </label>
                    <input
                      type="text"
                      name="linkedinURL"
                      // placeholder="https://linkedin.com/in/username"
                      value={values.linkedinURL}
                      onChange={handleChange}
                      className="input input-bordered w-full p-2 rounded bg-transparent border-1"
                    />
                    {touched.linkedinURL && errors.linkedinURL && (
                      <p className="text-sm text-red-700">{errors.linkedinURL}</p>
                    )}
                  </div>

                  {/*  */}

                  <div>
                    <label htmlFor="photo" className="block text-sm font-medium">
                      Candidate Photo :{" "}
                      {candidateProfile.photoURL
                        ? candidateProfile.photoURL.split("/").pop()
                        : "Required to add photo"}
                    </label>
                    <input
                      type="file"
                      name="photo"
                      id="photo"
                      accept="image/*"
                      // onChange={handleChange}
                      onChange={(event) => {
                        setFieldValue("photo", event.currentTarget.files[0]);
                      }}
                      className="mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-transparent border-1"
                    />
                    {touched.photo && errors.photo ? (<p className='text-sm text-red-700'>{errors.photo}</p>) : (null)}
                  </div>

                  <div>
                    <label htmlFor="resume" className="block text-sm font-medium">
                      Resume only (.pdf) *:{" "}
                      {candidateProfile.resumeURL
                        ? candidateProfile.resumeURL.split("/").pop()
                        : "Required to add resume"}
                    </label>
                    <input
                      type="file"
                      name="resume"
                      id="resume"
                      accept=".pdf"
                      // onChange={handleChange}
                      onChange={(event) => {
                        // Set the value of the file input to Formik
                        setFieldValue("resume", event.currentTarget.files[0]);
                      }}
                      className="mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-transparent border-1"
                    />
                    {touched.resume && errors.resume ? (<p className='text-sm text-red-700'>{errors.resume}</p>) : (null)}
                  </div>

                  {/* Add more fields similar to above */}

                  <div className="md:col-span-2 flex justify-end">
                    <button
                      type="button"
                      className="w-full md:w-auto hover:bg-blue-700 text-white font-medium py-2 px-4 mr-4 rounded"
                      style={{
                        backgroundColor: "transperant",
                        border: "1px solid #9900ff",
                        color: "#9900ff",
                      }}
                      onClick={() => navigate("/CandidateDashboard")}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="w-full md:w-auto hover:bg-blue-700 font-medium py-2 px-4 rounded"
                      style={{
                        backgroundColor: "#9900ff",
                        border: "1px solid #9900ff",
                        color: "#fff",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );



  // formik 1 end
  // );
};

export default EditCandidateProfileReg;
