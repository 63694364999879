// oiginal code start

// import React, { useState } from "react";
// import { API, graphqlOperation } from "aws-amplify";
// import { updateCompanyJobDescription } from "../../graphql/mutations";

// const JobPostForm = ({ initialValues, onCancel }) => {
//   const [formData, setFormData] = useState(initialValues);

//   const handleInputChange = (e) => {
//     setFormData({
//       ...formData,
//       [e.target.name]: e.target.value,
//     });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const input = {
//         id: formData.id,
//         companyProfileID: formData.companyProfileID,
//         jobIDNo: formData.jobIDNo,
//         jobTitle: formData.jobTitle,
//         selectedCategory: formData.selectedCategory,
//         selectedExperience: formData.selectedExperience,
//         skill: formData.skill,
//         jobDescription: formData.jobDescription,
//         compensation: formData.compensation,
//         benefits: formData.benefits,
//       };

//       const response = await API.graphql(
//         graphqlOperation(updateCompanyJobDescription, { input })
//       );

//       console.log("Job description updated successfully:", response);
//       onCancel(); // Close the modal after successful update
//     } catch (error) {
//       console.error("Error updating job description:", error);
//     }
//   };

//   return (
//     <div className="container mx-auto px-4 py-8  w-[800px]">
//       <h2 className="text-2xl font-bold mb-4">Edit Job Post</h2>
//       <form onSubmit={handleSubmit} className="w-[600px]">
//         <div className="mb-4">
//           <label
//             htmlFor="jobTitle"
//             className="block text-gray-700 font-bold mb-2"
//           >
//             Job Title
//           </label>
//           <input
//             type="text"
//             id="jobTitle"
//             name="jobTitle"
//             value={formData.jobTitle}
//             onChange={handleInputChange}
//             className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//           />
//         </div>
//         <div className="mb-4">
//           <label
//             htmlFor="selectedCategory"
//             className="block text-gray-700 font-bold mb-2"
//           >
//             Category
//           </label>
//           <input
//             type="text"
//             id="selectedCategory"
//             name="selectedCategory"
//             value={formData.selectedCategory}
//             onChange={handleInputChange}
//             className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//           />
//         </div>
//         <div className="mb-4">
//           <label
//             htmlFor="selectedExperience"
//             className="block text-gray-700 font-bold mb-2"
//           >
//             Experience Level
//           </label>
//           <input
//             type="text"
//             id="selectedExperience"
//             name="selectedExperience"
//             value={formData.selectedExperience}
//             onChange={handleInputChange}
//             className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//           />
//         </div>
//         <div className="mb-4">
//           <label htmlFor="skill" className="block text-gray-700 font-bold mb-2">
//             Skill
//           </label>
//           <input
//             type="text"
//             id="skill"
//             name="skill"
//             value={formData.skill}
//             onChange={handleInputChange}
//             className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//           />
//         </div>
//         <div className="mb-4">
//           <label
//             htmlFor="jobDescription"
//             className="block text-gray-700 font-bold mb-2"
//           >
//             Job Description
//           </label>
//           <textarea
//             id="jobDescription"
//             name="jobDescription"
//             value={formData.jobDescription}
//             onChange={handleInputChange}
//             className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//           />
//         </div>
//         <div className="mb-4">
//           <label
//             htmlFor="compensation"
//             className="block text-gray-700 font-bold mb-2"
//           >
//             Compensation
//           </label>
//           <input
//             type="text"
//             id="compensation"
//             name="compensation "
//             value={formData.compensation}
//             onChange={handleInputChange}
//             className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//           />
//         </div>
//         <div className="mb-4">
//           <label
//             htmlFor="benefits"
//             className="block text-gray-700 font-bold mb-2"
//           >
//             Benefits
//           </label>
//           <input
//             type="text"
//             id="benefits"
//             name="benefits"
//             value={formData.benefits}
//             onChange={handleInputChange}
//             className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
//           />
//         </div>
//         <div className="mb-4">
//           <button
//             type="submit"
//             className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//           >
//             Save
//           </button>
//           <button
//             type="button"
//             onClick={onCancel}
//             className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded ml-4"
//           >
//             Cancel
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

// export default JobPostForm;

// original code end

// testing code start

import React, { useState, useEffect, useRef } from "react";
import { API, graphqlOperation } from "aws-amplify";
// import { navigate } from "@reach/router"; // Import reach router for navigation
import { createCompanyJobDescription, updateCompanyJobDescription } from "../../graphql/mutations";
import { listCompanyJobDescriptions } from "../../graphql/queries";
import { Link, useNavigate } from "react-router-dom";


const JobPostForm = ({ initialValues, onCancel }) => {
  const [formData, setFormData] = useState(initialValues);
  const [jobTitle, setJobTitle] = useState(formData.jobTitle);
  const [selectedCategory, setSelectedCategory] = useState(formData.selectedCategory);
  const [selectedExperience, setSelectedExperience] = useState(formData.selectedExperience);
  const [skill, setSkill] = useState(formData.skill);
  const [jobDescription, setJobDescription] = useState(formData.jobDescription);
  const [compensation, setCompensation] = useState(formData.compensation);
  const [benefits, setBenefits] = useState(formData.benefits);
  const [workMode, setWorkMode] = useState(formData.workMode);
  const [companyjobcount, setcompanyjobcount] = useState(false);
  const compensationRef = useRef(null);

  const navigate= useNavigate()

  useEffect(() => {
    const fetchJobCount = async () => {
      const storedCompanyID = localStorage.getItem("CompanyProfileID");

      try {
        let nextToken = null;
        let jobCount = 0;

        do {
          const jobListResponse = await API.graphql(
            graphqlOperation(listCompanyJobDescriptions, {
              filter: { companyProfileID: { eq: storedCompanyID } },
              limit: 100,
              nextToken: nextToken,
            })
          );

          const jobDescriptions =
            jobListResponse.data.listCompanyJobDescriptions.items;
          jobCount += jobDescriptions.length;

          nextToken = jobListResponse.data.listCompanyJobDescriptions.nextToken;
        } while (nextToken);

        // Set company count
        if (jobCount > 0) {
          // You can set company job count here
          setcompanyjobcount(true);
        } else {
          console.log("No job descriptions found for the company.");
          setcompanyjobcount(false);
        }
      } catch (error) {
        console.error("Error fetching job count:", error);
        // Handle error appropriately
      }
    };

    fetchJobCount();
  }, []);

  const handleCompensationClick = () => {
    compensationRef.current.scrollIntoView({ behavior: "smooth" });
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   // Check if all mandatory fields are filled out
  //   if (
  //     !jobTitle ||
  //     !selectedCategory ||
  //     !selectedExperience ||
  //     !skill ||
  //     !jobDescription ||
  //     !compensation ||
  //     !benefits ||
  //     !workMode // Check if work mode is selected
  //   ) {
  //     alert("Please fill out all mandatory fields.");
  //     return;
  //   }

  //   try {
  //     const response = await API.graphql({
  //       query: createCompanyJobDescription,
  //       variables: {
  //         input: {
  //           jobTitle,
  //           selectedCategory,
  //           selectedExperience,
  //           skill,
  //           jobDescription,
  //           compensation,
  //           benefits,
  //           workMode, // Include work mode in the variables
  //           companyProfileID: localStorage.getItem("CompanyProfileID"),
  //           jobIDNo: 45, // Pass the actual value of jobIDNo variable here
  //         },
  //       },
  //     });

  //     if (response.errors && response.errors.length > 0) {
  //       console.error("Error submitting job details:", response.errors);
  //       // Show error message or handle the errors accordingly
  //     } else {
  //       console.log("Job details submitted successfully:", response);
  //       // Reset form
  //       setJobTitle("");
  //       setSelectedCategory("");
  //       setSelectedExperience("");
  //       setSkill("");
  //       setJobDescription("");
  //       setCompensation("");
  //       setBenefits("");
  //       setWorkMode(""); // Reset work mode
  //     }
  //     // navigate("/JobDetailsList");
  //   } catch (error) {
  //     console.error("Error submitting job details:", error);
  //     // Show error message
  //   }
  // };

  const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          const input = {
            id: formData.id,
            companyProfileID: formData.companyProfileID,
            jobIDNo: formData.jobIDNo,
            jobTitle: jobTitle,
            selectedCategory: selectedCategory,
            selectedExperience:selectedExperience,
            skill: skill,
            jobDescription:jobDescription,
            compensation:compensation,
            benefits:benefits,
            workMode,workMode
          };
    
          const response = await API.graphql(
            graphqlOperation(updateCompanyJobDescription, { input })
          );
    
          console.log("Job description updated successfully:", response);
          onCancel(); // Close the modal after successful update
        } catch (error) {
          console.error("Error updating job description:", error);
        }
      };


  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const handleExperienceSelect = (experience) => {
    setSelectedExperience(experience);
  };

  const renderCategoryButtons = () => {
    const categories = [
      "Full-time",
      "Part-time",
      "Contract",
      "Temporary",
      "Internship",
    ];
    return categories.map((category, index) => (
      <button
        key={index}
        style={{
          padding: "5px 15px",
          backgroundColor:
            selectedCategory === category ? "#9900ff" : "#f3f3f3",
          borderRadius: "15px",
          cursor: "pointer",
          transition: "background-color 0.3s",
          marginRight: "5px",
          marginBottom: "5px",
          color: selectedCategory === category ? "#fff" : "#000",
          border: "1px solid #9900ff",
        }}
        onClick={() => handleCategorySelect(category)}
      >
        {category}
      </button>
    ));
  };

  const renderExperienceButtons = () => {
    const experienceLevels = [
      { label: "Fresher\nJust out of college", value: "Fresher" },
      { label: "Entry Level\n1-3 years", value: "Entry Level 1-3 years" },
      { label: "Mid Level\n3-5 years", value: "Mid Level 3-5 years" },
      { label: "Senior Level\n5+ years", value: "Senior Level 5+ years" },
    ];

    return experienceLevels.map((experience, index) => (
      <button
        key={index}
        style={{
          backgroundColor:
            selectedExperience === experience.value ? "#9900ff" : "#1b1935",
          border: "1px solid #9900ff",
          borderRadius: "5px",
          padding: "15px 10px",
          textAlign: "center",
          fontSize: "14px",
          cursor: "pointer",
          flexGrow: "1",
          margin: "0 5px",
          transition: "border-color 0.3s ease",
        }}
        onClick={() => handleExperienceSelect(experience.value)}
      >
        {experience.label}
      </button>
    ));
  };

  const handleWorkModeSelect = (mode) => {
    setWorkMode(mode);
  };

  const renderWorkModeOptions = () => {
    const workModes = ["Work from home", "Remote", "Work from office"];

    return workModes.map((mode, index) => (
      <button
        key={index}
        style={{
          padding: "5px 15px",
          backgroundColor: workMode === mode ? "#9900ff" : "#f3f3f3",
          borderRadius: "15px",
          cursor: "pointer",
          transition: "background-color 0.3s",
          marginRight: "5px",
          marginBottom: "5px",
          color: workMode === mode ? "#fff" : "#000",
          border: "1px solid #9900ff",
        }}
        onClick={() => handleWorkModeSelect(mode)}
      >
        {mode}
      </button>
    ));
  };

  return (
    <div>
      {/* Conditionally render premium option */}
      {/* {companyjobcount === true &&
        CompanyEmailID !== "hr@varahitechnologies.com" ? (
          <div className="items-center text-center mt-14">
            <h1>you left your point</h1>
            <button className="border-1 hover:bg-[#9900FF] text-white font-bold py-2 px-4 rounded capitalize">
              Buy Premium
            </button>
          </div>
        ) : ( */}
      <div  
        style={{
          flexGrow: "1",
          padding: "20px",
          border: "2px solid #9900ff",
          borderRadius: "6px",
        }}
        className="mx-44 mt-16 "
      >
        <h1
          style={{
            color: "#ffffff",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          Update your Course Description
        </h1>

        <div style={{ marginBottom: "15px" }}>
          <label
            htmlFor="job-title"
            style={{
              display: "block",
              color: "#ffffff",
              marginBottom: "8px",
            }}
          >
            Course Title
          </label>
          <input
            type="text"
            id="job-title"
            placeholder="Enter job title"
            value={jobTitle}
            onChange={(e) => setJobTitle(e.target.value)}
            style={{
              width: "97%",
              padding: "10px",
              marginBottom: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              backgroundColor: "transparent",
              color: "#ffffff",
            }}
          />
        </div>
        <div style={{ marginBottom: "20px" }}>
          <label
            htmlFor="job-search"
            style={{
              display: "block",
              color: "#ffffff",
              marginBottom: "8px",
            }}
          >
            Course Category
          </label>
          <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
            {renderCategoryButtons()}
          </div>
        </div>

        <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "10px",
              color: "#ffffff",
            }}
          >
            Work Mode
          </label>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              marginBottom: "20px",
            }}
          >
            {renderWorkModeOptions()}
          </div>
        </div>

        <div style={{ marginBottom: "20px" }}>
          <label
            style={{
              display: "block",
              marginBottom: "10px",
              color: "#ffffff",
            }}
          >
            Experience Level
          </label>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
              boxSizing: "border-box",
              maxWidth: "600px",
              margin: "auto",
            }}
          >
            {renderExperienceButtons()}
          </div>
        </div>

        <div style={{ marginBottom: "15px" }}>
          <label
            htmlFor="skill"
            style={{
              display: "block",
              color: "#ffffff",
              marginBottom: "8px",
            }}
          >
            Skill
          </label>
          <input
            type="text"
            id="skill"
            placeholder="Enter skills separated by comma"
            value={skill}
            onChange={(e) => setSkill(e.target.value)}
            style={{
              width: "97%",
              padding: "10px",
              marginBottom: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              backgroundColor: "transparent",
              color: "#ffffff",
            }}
          />
        </div>

        <div style={{ marginBottom: "15px" }}>
          <label
            htmlFor="course-description"
            style={{
              display: "block",
              color: "#ffffff",
              marginBottom: "8px",
            }}
          >
            Course Description
          </label>
          <textarea
            id="course-description"
            placeholder="Enter job description"
            rows="4"
            value={jobDescription}
            onChange={(e) => setJobDescription(e.target.value)}
            style={{
              width: "97%",
              padding: "10px",
              marginBottom: "10px",
              border: "1px solid #ccc",
              borderRadius: "4px",
              backgroundColor: "transparent",
              color: "#ffffff",
            }}
          ></textarea>
        </div>

        <div ref={compensationRef}>
          <div style={{ marginBottom: "15px" }}>
            <label
              htmlFor="compensation"
              style={{
                display: "block",
                color: "#ffffff",
                marginBottom: "8px",
              }}
            >
              Compensation
            </label>
            <input
              type="text"
              id="compensation"
              placeholder="eg : 6LPA-12LPA"
              value={compensation}
              onChange={(e) => setCompensation(e.target.value)}
              style={{
                width: "97%",
                padding: "10px",
                marginBottom: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                backgroundColor: "transparent",
                color: "#ffffff",
              }}
            />
          </div>

          <div style={{ marginBottom: "15px" }}>
            <label
              htmlFor="benefits"
              style={{
                display: "block",
                color: "#ffffff",
                marginBottom: "8px",
              }}
            >
              Benefits
            </label>
            <textarea
              id="benefits"
              placeholder="Enter benefits details"
              rows="4"
              value={benefits}
              onChange={(e) => setBenefits(e.target.value)}
              style={{
                width: "97%",
                padding: "10px",
                marginBottom: "10px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                backgroundColor: "transparent",
                color: "#ffffff",
              }}
            ></textarea>
          </div>

          <div style={{ textAlign: "right" }}>
           
            <button
              type="button"
              onClick={() => {
                // Handle cancel action
                onCancel(); // Navigate to JobDetailsList
              }}
              style={{
                padding: "10px 15px",
                marginLeft: "10px",
                backgroundColor: "#d9534f",
                color: "#ffffff",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              Cancel
            </button>
            
            <button
              type="submit"
              onClick={handleSubmit}
              style={{
                padding: "10px 15px",
                marginLeft: "10px",
                backgroundColor: "#5cb85c",
                color: "#ffffff",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobPostForm;


// testing code end
