import React, { useState, useEffect, useRef } from "react";
import { API, graphqlOperation, Auth } from "aws-amplify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  listAdminAddCandidateContexts,
  listCandidateProfiles,
  listInterviewSlots,
  listInterviewEvaluations,
} from "../graphql/queries";
import { RiseLoader } from "react-spinners";
import logo from "../assets/logo.svg";
// import ProfileModal from "./ProfileModal";
import Header from "../components/header/Header";
// Modal component

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};
function CandidateDashboard({ signOut }) {
  const [isLoading, setIsLoading] = useState(true);
  const rootElementRef = useRef(null);
  const [isMounted, setIsMounted] = useState(false);
  const [hasScheduledInterview, setHasScheduledInterview] = useState(false);
  const [hasCompletedProfile, setHasCompletedProfile] = useState(false);
  const [userFullName, setUserFullName] = useState("");
  const [candidateEmail, setCandidateEmail] = useState("");
  const [showEmail, setShowEmail] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [candidateProfile, setCandidateProfile] = useState(null);
  const [interviewEvaluations, setInterviewEvaluations] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const scheduled = location.state ? location.state.scheduled : false;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        // Check if user is authenticated
        const userInfo = await Auth.currentAuthenticatedUser();
        const userId = userInfo.attributes.sub;

        // Fetch user profile with pagination
        let nextToken = null;
        let userProfileList = [];
        do {
          const userProfileResponse = await API.graphql(
            graphqlOperation(listCandidateProfiles, {
              filter: { userID: { eq: userId } },
              limit: 100,
              nextToken: nextToken,
            })
          );
          userProfileList.push(
            ...userProfileResponse.data.listCandidateProfiles.items
          );
          nextToken = userProfileResponse.data.listCandidateProfiles.nextToken;
        } while (nextToken);

        // Set user profile details
        if (userProfileList.length > 0) {
          setUserFullName(userProfileList[0].fullName);
          setHasCompletedProfile(userProfileList[0].fullName.trim() !== "");
        } else {
          console.log("User profile not found.");
          setHasCompletedProfile(false);
        }

        // Fetch candidate profile and interview evaluations
        const candidateEmail = localStorage.getItem("candidateProfileEmailID");
        if (candidateEmail) {
          // Fetch candidate contexts with pagination
          nextToken = null;
          let adminContexts = [];
          do {
            const response = await API.graphql(
              graphqlOperation(listAdminAddCandidateContexts, {
                limit: 100,
                nextToken: nextToken,
              })
            );
            adminContexts.push(
              ...response.data.listAdminAddCandidateContexts.items
            );
            nextToken = response.data.listAdminAddCandidateContexts.nextToken;
          } while (nextToken);

          const foundProfile = adminContexts.some(
            (profile) => profile.candidateEmail === candidateEmail
          );
          setCandidateProfile(foundProfile);

          // Fetch interview evaluations with pagination
          nextToken = null;
          let evaluationsItems = [];
          do {
            const response = await API.graphql(
              graphqlOperation(listInterviewEvaluations, {
                filter: { candidateEmail: { eq: candidateEmail } },
                limit: 100,
                nextToken: nextToken,
              })
            );
            evaluationsItems.push(
              ...response.data.listInterviewEvaluations.items
            );
            nextToken = response.data.listInterviewEvaluations.nextToken;
          } while (nextToken);

          setInterviewEvaluations(evaluationsItems.length > 0);
        }

        // Check for scheduled interviews with pagination
        if (candidateEmail) {
          nextToken = null;
          let interviewSlots = [];
          do {
            const interviewSlotsResponse = await API.graphql(
              graphqlOperation(listInterviewSlots, {
                filter: { candidateEmail: { eq: candidateEmail } },
                limit: 100,
                nextToken: nextToken,
              })
            );
            interviewSlots.push(
              ...interviewSlotsResponse.data.listInterviewSlots.items
            );
            nextToken =
              interviewSlotsResponse.data.listInterviewSlots.nextToken;
          } while (nextToken);

          setHasScheduledInterview(interviewSlots.length > 0);
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    setIsMounted(true);
    fetchData();
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col py-3">
          {/* Header */}
          <Header />
          {/* Centered content */}
          {isLoading ? ( // Conditional rendering for loader
            <div className="flex justify-center items-center h-screen">
              <RiseLoader
                color={"#ffffff"}
                loading={isLoading}
                css={override}
                size={40}
                aria-label="Loading Spinner"
              />
            </div>
          ) : (
            <div className="d-flex flex-column align-items-center justify-content-center py-3">
              <div className="row position-relative">
                <div className="col-md-12 mb-4 position-relative d-flex">
                  <div
                    className="p-3 border rounded animate__animated animate__fadeInRight d-flex flex-column justify-content-between align-items-center"
                    style={{
                      height: "500px",
                      width: "100%",
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    {hasCompletedProfile ? (
                      <>
                        {hasScheduledInterview ||
                        candidateProfile ||
                        interviewEvaluations ? (
                          <>
                            {candidateProfile || interviewEvaluations ? (
                              <div
                                className="text-center mt-5"
                                style={{ position: "relative", zIndex: "1" }}
                              >
                                <h2 style={{ fontStyle: "italic" }}>
                                  View Your Profile
                                </h2>
                                <p style={{ fontStyle: "italic" }}>
                                  You can view your profile details below.
                                </p>
                                <button
                                  style={{
                                    border: "2px solid #9900FF",
                                    backgroundColor: "#9900ff4c",
                                    borderRadius: "8px",
                                    padding: "12px",
                                    color: "#FFFFFF",
                                  }}
                                  onClick={() => {
                                    // Navigate to the profile view page
                                    window.location.href =
                                      "/CandidateResumeDetails";
                                  }}
                                >
                                  View Profile
                                </button>
                              </div>
                            ) : (
                              <div style={{ paddingTop: "15vh", zIndex: "1" }}>
                                <h2 style={{ fontStyle: "italic" }}>
                                  Congratulations!
                                </h2>
                                <p style={{ fontStyle: "italic" }}>
                                  Your interview slot is booked. Scheduled
                                  interview details have been sent to your
                                  email. Please kindly check your email and join
                                  on time.
                                </p>
                                <p style={{ fontStyle: "italic" }}>
                                  We are currently working on some other
                                  features, which will be available shortly.
                                </p>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="container">
                            <div className="row justify-content-center mt-5">
                              <div className="col-md-8">
                                <div className="text-center">
                                  <h2 style={{ fontStyle: "italic" }}>
                                    Schedule Your Interview Slot
                                  </h2>
                                  <p style={{ fontStyle: "italic" }}>
                                    You haven't scheduled your interview slot
                                    yet. Click below to schedule your interview.
                                  </p>
                                  <button
                                    style={{
                                      position: "relative",
                                      zIndex: "1",
                                      border: "2px solid #9900FF",
                                      backgroundColor: "#9900ff4c",
                                      borderRadius: "8px",
                                      padding: "12px",
                                      color: "#FFFFFF",
                                    }}
                                    className="btn btn-primary"
                                    onClick={() =>
                                      (window.location.href =
                                        "/candidateSchedule")
                                    }
                                  >
                                    Schedule Interview
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="container" style={{ zIndex: 1 }}>
                        <div className="row justify-content-center mt-5">
                          <div className="col-md-10">
                            <div className="text-center">
                              <h2 style={{ fontStyle: "italic" }}>
                                Complete Your Profile
                              </h2>
                              <p style={{ fontStyle: "italic" }}>
                                First, complete your profile to proceed further.
                              </p>
                              <button
                                className="btn btn-primary"
                                style={{
                                  border: "2px solid #9900FF",
                                  backgroundColor: "#9900ff4c",
                                  borderRadius: "8px",
                                  padding: "12px",
                                  color: "#FFFFFF",
                                  zIndex: "1",
                                }}
                                onClick={() =>
                                  (window.location.href =
                                    "/candidateProfileReg")
                                }
                              >
                                Complete Profile
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="diagonal-split"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default withAuthenticator(CandidateDashboard);
