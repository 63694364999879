import React, { useEffect, useState } from 'react'
import { API, graphqlOperation ,Auth} from "aws-amplify";
import { listCompanyProfiles } from '../../graphql/queries';
import CompanyHome from '../../pages/Company/CompanyHome';
import WhoAreYou from '../../pages/WhoAreYou';
import { useNavigate } from 'react-router-dom';
import CandidateDashboard from '../../pages/CandidateDashboard';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { listCandidateProfiles } from '../../graphql/queries';

const PrivateRoute = () => {
    const[email,setEmail]=useState("")
    const [companyList, setCompanyList] = useState([]); 
    const [nextToken, setNextToken] = useState(null);
    const [loading, setLoading] = useState(true);
    const [company,setCompany]=useState({})

    //from below code for candidate

    
    const [hasScheduledInterview, setHasScheduledInterview] = useState(false);
    const [hasCompletedProfile, setHasCompletedProfile] = useState(false);
    const [userFullName, setUserFullName] = useState("");
    const [candidate, setCandidate] = useState({})
    const navigate = useNavigate()
    // useEffect(() => {

    //     const candidateProfileEmailID = localStorage.getItem(
    //         "candidateProfileEmailID"
    //       );

    //       setEmail(candidateProfileEmailID)

       

    //      const getData = async() => {

    //         const gettingdata = await API.graphql(
    //             graphqlOperation(listCompanyProfiles, {
    //               filter: {
    //                 email: { eq: candidateProfileEmailID },
    //               },
    //             })
    //           );

          
    //         setCompany(gettingdata)
              

    //      }
          
    //      getData()


    // },[])

    useEffect(() => {
        const fetchData = async () => {
          try {
            const candidateProfileEmailID = localStorage.getItem('candidateProfileEmailID');
            setEmail(candidateProfileEmailID);
            console.log("Email id",candidateProfileEmailID)
    
            if (!candidateProfileEmailID) {
              // Handle case where candidate email is not found
              return;
            }
    
            const { data } = await API.graphql(
              graphqlOperation(listCompanyProfiles, {
                filter: {
                  email: { eq: candidateProfileEmailID },
                },
                limit: 100, // Limit the number of items per page, adjust as needed
                nextToken: nextToken // Pass nextToken if available
              })
            );
            console.log("==================:",data)
            setCompany(data.listCompanyProfiles.items[0])
            
    
            if (data && data.listCompanyProfiles.items) {
              const { items, nextToken } = data.listCompanyProfiles;
              setCompanyList(prevList => [...prevList, ...items]); // Merge new items with existing list
              setNextToken(nextToken); // Set the nextToken for pagination
              setLoading(false); // Set loading to false after data is fetched
            }
          } catch (error) {
            console.error('Error fetching data:', error);
            // Handle error
          }
        };
         
       
        fetchData();
       
        

        // getting candidate information

         const getData = async () => {
          
          const userInfo = await Auth.currentAuthenticatedUser();
          const userId = userInfo.attributes.sub;

          let nextToken = null;
        let userProfileList = [];
        do {
          const userProfileResponse = await API.graphql(
            graphqlOperation(listCandidateProfiles, {
              filter: { userID: { eq: userId } },
              limit: 100,
              nextToken: nextToken,
            })
          );
          console.log("userProfileResponse from private route",userProfileResponse)
          
          console.log("candidate from private route",candidate)
          userProfileList.push(
            ...userProfileResponse.data.listCandidateProfiles.items
          );
          nextToken = userProfileResponse.data.listCandidateProfiles.nextToken;
        } while (nextToken);

        // Set user profile details
        if (userProfileList.length > 0) {
          setUserFullName(userProfileList[0].t_c);
          setHasCompletedProfile(userProfileList[0].t_c === true);
        } else {
          console.log("User profile not found.");
          setHasCompletedProfile(false);
        }

         }
       
         getData()

        

      }, [nextToken]); // Fetch data again when nextToken changes
    

    // console.log("Comapy Data from private route ",company.companyUser)
    // console.log("candidate find from private route",userFullName)

  return (
    // (company && company.companyUser)? (<CompanyHome/>): (<CandidateDashboard/>)
  
   // (company && company.companyUser) ? <CompanyHome/> : (userFullName ? <CandidateDashboard/> : <WhoAreYou />)

   company ? (
    <CompanyHome/>
  ) : userFullName ? (
    <CandidateDashboard/>
  ) : (
    <WhoAreYou />
  )

  )
 
}

export default PrivateRoute
