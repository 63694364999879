import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API, graphqlOperation, Storage, Auth } from "aws-amplify";
import { updateCandidateProfile } from "../graphql/mutations";
import awsExports from "../aws-exports"; // Adjust this import path as necessary
import { RiseLoader } from "react-spinners";
import { listAdminAddCandidateContexts } from "../graphql/queries";
import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';

// Assuming awsExports is properly configured
API.configure(awsExports);
Auth.configure(awsExports);
Storage.configure(awsExports);

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

const CandidateProfileReg = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [candidateEmail, setCandidateEmail] = useState("");
  const [file, setFile] = useState(null)
  // const [candidateProfile, setCandidateProfile] = useState({
  //   id: localStorage.getItem("candidateProfileId") || "", // Retrieve ID from local storage
  //   fullName: "",
  //   gender: "",
  //   mobileNumber: "",
  //   description: "",
  //   matchedPercentage: 0,
  //   workStatus: "",
  //   currentCity: "",
  //   educationDetails: "",
  //   keySkills: "",
  //   workPreference: "",
  //   preferredSalary: "",
  //   photo: null,
  //   resume: null,
  //   photoURL: "",
  //   resumeURL: "",
  //   roleApplyFor: "", // New field
  //   address: "", // New field
  // });

  const candidateProfileEmailID = localStorage.getItem(
    "candidateProfileEmailID"
  );

  useEffect(() => {
    // Retrieve the candidateProfileEmailID from local storage
    const candidateProfileEmailID = localStorage.getItem(
      "candidateProfileEmailID"
    );

    // Update the content of the span element with the candidateProfileEmailID
    setCandidateEmail(candidateProfileEmailID);
  }, []);
  const [candidateProfileView, setCandidateProfileView] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await API.graphql(
          graphqlOperation(listAdminAddCandidateContexts)
        );
        const items = response.data.listAdminAddCandidateContexts.items;

        // Check if candidateEmail exists in any of the profiles
        const foundProfile = items.some(
          (profile) => profile.candidateEmail === candidateEmail
        );

        // Set candidateProfile based on whether the candidateEmail exists in the profiles
        setCandidateProfileView(foundProfile);
      } catch (error) {
        console.error("Error fetching candidate data:", error);
      }
    };

    // Fetch data only if candidateEmail exists
    if (candidateEmail) {
      fetchData();
    }
  }, [candidateEmail]);

  // const handleChange = (e) => {
  //   const { name, value, files } = e.target;
  //   if (files) {
  //     setCandidateProfile((prevState) => ({ ...prevState, [name]: files[0] }));
  //   } else {
  //     setCandidateProfile((prevState) => ({ ...prevState, [name]: value }));
  //   }
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   // Check if required fields are filled
  //   if (
  //     !candidateProfile.fullName ||
  //     !candidateProfile.mobileNumber ||
  //     !candidateProfile.workStatus ||
  //     !candidateProfile.roleApplyFor ||
  //     !candidateProfile.address ||
  //     !candidateProfile.resume
  //   ) {
  //     alert("Please fill in all * fields.");
  //     return;
  //   }
  //   setLoading(true); // Set loading to true when submitting
  //   const yourS3BucketName = "getscreenedstoragebucket71511-staging"; // Adjust with your S3 Bucket name

  //   // File upload handling
  //   if (candidateProfile.photo) {
  //     try {
  //       const { key: photoKey } = await Storage.put(
  //         `${Date.now()}_${candidateProfile.photo.name}`,
  //         candidateProfile.photo,
  //         { contentType: "image/*" } // or candidateProfile.photo.type for more specific types
  //       );
  //       candidateProfile.photoURL = `https://${yourS3BucketName}.s3.amazonaws.com/public/${photoKey}`;
  //     } catch (error) {
  //       console.error("Error uploading photo:", error);
  //       return;
  //     }
  //   }

  //   if (candidateProfile.resume) {
  //     try {
  //       const { key: resumeKey } = await Storage.put(
  //         `${Date.now()}_${candidateProfile.resume.name}`,
  //         candidateProfile.resume,
  //         { contentType: "application/pdf" } // or candidateProfile.resume.type for more specific types
  //       );
  //       candidateProfile.resumeURL = `https://${yourS3BucketName}.s3.amazonaws.com/public/${resumeKey}`;
  //     } catch (error) {
  //       console.error("Error uploading resume:", error);
  //       return;
  //     }
  //   }

  //   // Update profile with new data
  //   try {
  //     const updateInput = {
  //       id: candidateProfile.id, // ID retrieved from local storage
  //       fullName: candidateProfile.fullName,
  //       gender: candidateProfile.gender,
  //       mobileNumber: candidateProfile.mobileNumber,
  //       description: candidateProfile.description,
  //       matchedPercentage: parseFloat(candidateProfile.matchedPercentage),
  //       workStatus: candidateProfile.workStatus,
  //       currentCity: candidateProfile.currentCity,
  //       educationDetails: candidateProfile.educationDetails,
  //       keySkills: candidateProfile.keySkills,
  //       workPreference: candidateProfile.workPreference,
  //       preferredSalary: parseFloat(candidateProfile.preferredSalary),
  //       photoURL: candidateProfile.photoURL,
  //       resumeURL: candidateProfile.resumeURL,
  //       roleApplyFor: candidateProfile.roleApplyFor, // New field
  //       address: candidateProfile.address, // New field
  //     };

  //     await API.graphql(
  //       graphqlOperation(updateCandidateProfile, { input: updateInput })
  //     );
  //     console.log("Profile updated successfully");
  //     // navigate("/candidateSchedule"); // Navigate to the desired path after successful update
  //     // Conditionally navigate based on candidateProfile and candidateProfileEmailID
  //     if (candidateProfileView) {
  //       // navigate("/CandidateResumeDetails"); // Navigate to CandidateResumeDetails
  //       navigate("/CandidateDashboard"); // Navigate to CandidateResumeDetails
  //     } else {
  //       navigate("/candidateSchedule"); // Navigate to candidateSchedule
  //     }
  //   } catch (error) {
  //     console.error("Error updating candidate profile:", error);
  //   } finally {
  //     setLoading(false); // Reset loading state after submission is complete
  //   }
  // };

  // formmik yup code start

  // const handleFileChange = (event) => {
  //   handleChange(event); // Let Formik handle the change event
  //   const selectedFile = event.currentTarget.files[0];
  //   setFieldValue('resume', selectedFile); // Set file value manually
  //   // console.log("------------------------------------",event.currentTarget.files[0])
  //   // console.log("------------------------------------", selectedFile);
  //   setFieldValue('resume', selectedFile); // Set file value manually
  //   setFile(selectedFile); // Assuming setFile is a function to set file state
  // }

  const handleFileChange = (event) => {
    handleChange(event); // Let Formik handle the change event
    const selectedFile = event.currentTarget.files[0];
    setFieldValue('sop', selectedFile); // Set file value manually
    // console.log("------------------------------------",event.currentTarget.files[0])
    // console.log("------------------------------------", selectedFile);
    setFieldValue('sop', selectedFile); // Set file value manually
    setFile(selectedFile); // Assuming setFile is a function to set file state
  }

  const handlePhotoChange = (event) => {

    handleChange(event); // Let Formik handle the change event
    const selectedFile = event.currentTarget.files[0];
    setFieldValue('photo', selectedFile); // Set file value manually
    // console.log("------------------------------------",event.currentTarget.files[0])
    // console.log("------------------------------------", selectedFile);
    setFieldValue('photo', selectedFile); // Set file value manually
    setFile(selectedFile); // Assuming setFile is a function to set file state

  }


  const signupschema = Yup.object({
    fullName: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Full name must contain only letters")
      .min(2, "Please Enter vaild Name")
      .max(25, "Full name must not exceed 25 characters")
      .required("Please Enter Full name"),
    mobileNumber: Yup.string()
      .matches(/^[0-9]{10}$/, 'Mobile Number must be exactly 10 digits')
      .required('Mobile Number is required'),

    // roleApplyFor: Yup.string()
    //   .matches(/^[A-Za-z\s]+$/, "must contain only letters")
    //   .required('Role Apply For is required'),
    address: Yup.string().required('Address is required'),
    // educationDetails: Yup.string()
    //   .matches(/^[A-Za-z\s]+$/, "Education Details must contain only letters"),

    currentCity: Yup.string()
      .required('city is required')
      .matches(/^[A-Za-z\s]+$/, " must contain only letters"),



    keySkills: Yup.string()
      .required('Skills must be there')
      .matches(/^[a-zA-Z\s,.\\-]+$/, 'Only letters, spaces, commas, periods, and hyphens are allowed'),


    description: Yup.string()
      .required('Description must be there')
      // .matches(/^[a-zA-Z0-9\s,.']+$/, 'Only letters, numbers, spaces, commas, dots, and single quotes are allowed'),
      .matches(/^[a-zA-Z0-9\s,.!'()-]+$/, {
        message: 'Only letters, numbers, spaces, commas, dots, single quotes, and certain special characters are allowed',
        excludeEmptyString: true, // Exclude empty string from validation
      }),

    // resume: Yup.mixed()
    //   .required('pdf is required')
    //   .test('fileType', 'Only PDF files are allowed', (value) =>

    //     value ? value.type === 'application/pdf' : true
    //     // console.log("[][][][][][]][][][]][][][",value.size)
    //   )
    //   .test('fileSize', 'File size should be between 5 KB and 25 MB', (value) =>
    //     value ? value.size >= 5 * 1024 && value.size <= 5 * 1024 * 1024 : true
    //   ),
    sop: Yup.mixed()
    .required('pdf is required')
    .test('fileType', 'Only PDF files are allowed', (value) =>

      value ? value.type === 'application/pdf' : true
      // console.log("[][][][][][]][][][]][][][",value.size)
    )
    .test('fileSize', 'File size should be between 5 KB and 25 MB', (value) =>
      value ? value.size >= 5 * 1024 && value.size <= 5 * 1024 * 1024 : true
    ),

    // photo: Yup.mixed()
    //   .test('fileType', 'Invalid file format', (value) => {
    //     if (!value) return true; // Allow empty values (no file selected)
    //     // console.log("image information--------------------------------",value)
    //     return value && value.type && value.type.split('/')[0] === 'image'; // Check if the file type is an image
    //   })
    //   .test('fileSize', 'File size should be between 5 KB and 25 MB', (value) =>
    //     value ? value.size >= 5 * 1024 && value.size <= 25 * 1024 * 1024 : true
    //   ),

    photo: Yup.mixed()
      .nullable()
      .test('fileType', 'Invalid file format', function (value) {
        if (!value) return true; // Allow empty values (no file selected)
        return value && value.type && value.type.split('/')[0] === 'image'; // Check if the file type is an image
      })
      .test('fileSize', 'File size should be between 5 KB and 25 MB', function (value) {
        return !value || (value.size >= 5 * 1024 && value.size <= 25 * 1024 * 1024);
      }),


    universityApplyFor: Yup.string()
      .required('University applying for is required'),
    courseApplyingFor: Yup.string()
      .required('Course applying for is required'),


  })
  // console.log("\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\",file)


  const initialValues = {
    id: localStorage.getItem("candidateProfileId") || "", // Retrieve ID from local storage
    fullName: "",
    gender: "",
    mobileNumber: "",
    description: "",
    matchedPercentage: 0,
    currentCity: "",
    educationDetails: "",
    keySkills: "",
    photo: null,
    // resume: null,
    sop:null,
    photoURL: "",
    // resumeURL: "",
    sopURL:"",
    // roleApplyFor: "", // New field
    address: "", // New field
    linkedinURL: "",
    universityApplyFor: "", // New field
    courseApplyingFor: "", // New field
    //



  };

  const { values, errors, handleChange, touched, handleSubmit, setFieldValue, handleBlur } = useFormik({
    initialValues: initialValues,
    validationSchema: signupschema,
    onSubmit: async (values) => {
      console.log("++++++++++++++++++++++++++++++++++++++", values)

      const yourS3BucketName = "getscreenedstoragebucket4cd75-unibackend"; // Adjust with your S3 Bucket name

      // File upload handling
      // console.log("[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[[", values.photo)
      // console.log("]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]]", values.resume)
      if (values.photo) {
        try {
          const { key: photoKey } = await Storage.put(
            `${Date.now()}_${values.photo.name}`,
            values.photo,
            { contentType: "image/*" } // or candidateProfile.photo.type for more specific types
          );
          values.photoURL = `https://${yourS3BucketName}.s3.amazonaws.com/public/${photoKey}`;
        } catch (error) {
          console.error("Error uploading photo:", error);
          return;
        }
      }

      //   if (candidateProfile.photo) {
      //     try {
      //       const { key: photoKey } = await Storage.put(
      //         `${Date.now()}_${candidateProfile.photo.name}`,
      //         candidateProfile.photo,
      //         { contentType: "image/*" } // or candidateProfile.photo.type for more specific types
      //       );
      //       candidateProfile.photoURL = `https://${yourS3BucketName}.s3.amazonaws.com/public/${photoKey}`;
      //     } catch (error) {
      //       console.error("Error uploading photo:", error);
      //       return;
      //     }
      //   }



      // if (values.resume) {
      //   try {
      //     const { key: resumeKey } = await Storage.put(
      //       `${Date.now()}_${values.resume.name}`,
      //       values.resume,
      //       { contentType: "application/pdf" } // or candidateProfile.resume.type for more specific types
      //     );
      //     values.resumeURL = `https://${yourS3BucketName}.s3.amazonaws.com/public/${resumeKey}`;
      //   } catch (error) {
      //     console.error("Error uploading resume:", error);
      //     return;
      //   }
      // }

      if (values.sop) {
        try {
          const { key: resumeKey } = await Storage.put(
            `${Date.now()}_${values.sop.name}`,
            values.sop,
            { contentType: "application/pdf" } // or candidateProfile.resume.type for more specific types
          );
          values.sopURL = `https://${yourS3BucketName}.s3.amazonaws.com/public/${resumeKey}`;
        } catch (error) {
          console.error("Error uploading resume:", error);
          return;
        }
      }

      // console.log("11111111111111111111111111111111111111", values)
      // const Responce = await API.graphql(
      //       graphqlOperation(updateCandidateProfile, { input: values })
      //  );
      //     console.log("Profile updated successfully",Responce);
      //     // navigate("/candidateSchedule"); // Navigate to the desired path after successful update
      //     // Conditionally navigate based on candidateProfile and candidateProfileEmailID
      //     if (candidateProfileView) {
      //       // navigate("/CandidateResumeDetails"); // Navigate to CandidateResumeDetails
      //       navigate("/CandidateDashboard"); // Navigate to CandidateResumeDetails
      //     } else {
      //       navigate("/candidateSchedule"); // Navigate to candidateSchedule
      //     }



      const mutationInput = {
        id: values.id, // ID retrieved from local storage
        fullName: values.fullName,
        gender: values.gender,
        mobileNumber: values.mobileNumber,
        description: values.description,
        matchedPercentage: parseFloat(values.matchedPercentage),
        currentCity: values.currentCity,
        educationDetails: values.educationDetails,
        keySkills: values.keySkills,
        photoURL: values.photoURL,
        // resumeURL: values.resumeURL,
        sopURL:values.sopURL,
        // roleApplyFor: values.roleApplyFor, // New field
        address: values.address, // New field
        linkedinURL: values.linkedinURL,
        universityApplyFor: values.universityApplyFor,
        courseApplyingFor: values.courseApplyingFor,

      };

      const response = await API.graphql(
        graphqlOperation(updateCandidateProfile, { input: mutationInput })
      );


      const { fullName } = response.data.updateCandidateProfile;
      localStorage.setItem('UniversityCandidateName', fullName);

      // navigate("/candidateSchedule"); // Navigate to the desired path after successful update
      // Conditionally navigate based on candidateProfile and candidateProfileEmailID
      if (candidateProfileView) {
        // navigate("/CandidateResumeDetails"); // Navigate to CandidateResumeDetails
        navigate("/CandidateDashboard"); // Navigate to CandidateResumeDetails
      } else {
        navigate("/candidateSchedule"); // Navigate to candidateSchedule
      }









    }
  })



  console.log("---------------------------------", values)
  // console.log("+++++++++++++++++++++++++++++++++", errors)



  return (
    <div
      className="min-h-screen  text-white flex justify-center items-center"
      style={{ backgroundColor: "#101222" }}
    >
      <div className="container mx-auto px-4">
        <div
          className=" rounded shadow-lg p-6 md:p-8"
          style={{
            margin: "50px",
            backgroundColor: "#1a1934",
            color: "#ffffff",
            width: "-webkit-fill-available",
            height: "inherit",
          }}
        >
          <h2 className="text-2xl md:text-3xl font-bold mb-6 text-center ">
            Candidate Profile Registration
          </h2>
          {loading ? ( // Conditional rendering for loader
            <div className="flex justify-center items-center">
              <RiseLoader
                color={"#ffffff"}
                loading={loading}
                css={override}
                size={40}
                aria-label="Loading Spinner"
              />
            </div>
          ) : (
            <form
              onSubmit={handleSubmit}
              className="grid grid-cols-1 md:grid-cols-2 gap-6"
            >
              <div>
                <label
                  htmlFor="fullName"
                  className="block text-sm font-medium "
                >
                  Full Name *
                </label>
                <input
                  type="text"
                  name="fullName"
                  placeholder="e.g. John Doe"
                  value={values.fullName}
                  onChange={handleChange}
                  className="input input-bordered w-full p-2 rounded"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                  }}
                />
                {touched.fullName && errors.fullName ? (<p className='text-sm text-red-700'>{errors.fullName}</p>) : (null)}
              </div>
              <div>
                <label htmlFor="gender" className="block text-sm font-medium ">
                  Gender
                </label>
                <select
                  name="gender"
                  onChange={handleChange}
                  value={values.gender}
                  className="select select-bordered w-full p-2 rounded"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                  }}
                >
                  <option selected style={{ color: "#000" }}>
                    Gender
                  </option>
                  <option value="male" style={{ color: "#000" }}>
                    Male
                  </option>
                  <option value="female" style={{ color: "#000" }}>
                    Female
                  </option>
                  <option value="other" style={{ color: "#000" }}>
                    Other
                  </option>
                </select>
              </div>
              <div>
                <label
                  htmlFor="mobileNumber"
                  className="block text-sm font-medium "
                >
                  Mobile Number *
                </label>
                <input
                  type="text"
                  name="mobileNumber"
                  placeholder="Enter your mobile number"
                  value={values.mobileNumber}
                  onChange={handleChange}
                  className="input input-bordered w-full p-2 rounded"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                  }}
                />
                {touched.mobileNumber && errors.mobileNumber ? (<p className='text-sm text-red-700'>{errors.mobileNumber}</p>) : (null)}
              </div>
              <div>
                <label
                  htmlFor="currentCity"
                  className="block text-sm font-medium "
                >
                  Current City
                </label>
                <input
                  type="text"
                  name="currentCity"
                  placeholder="E.g., New York, London, Delhi"
                  value={values.currentCity}
                  onChange={handleChange}
                  className="input input-bordered w-full p-2 rounded"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                  }}
                />
                {touched.currentCity && errors.currentCity ? (<p className='text-sm text-red-700'>{errors.currentCity}</p>) : (null)}
              </div>
              <div>
                <label
                  htmlFor="description"
                  className="block text-sm font-medium "
                >
                  Description
                </label>
                <textarea
                  name="description"
                  id="description"
                  placeholder="Briefly describe your career goals"
                  value={values.description}
                  onChange={handleChange}
                  className="mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                  }}
                ></textarea>
                {touched.description && errors.description ? (<p className='text-sm text-red-700'>{errors.description}</p>) : (null)}
              </div>
              <div>
                <label htmlFor="address" className="block text-sm font-medium">
                  Address *
                </label>
                <textarea
                  type="text"
                  name="address"
                  placeholder="Include street, city, and postal code"
                  value={values.address}
                  onChange={handleChange}
                  className="input input-bordered w-full p-2 rounded"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                  }}
                />
                {touched.address && errors.address ? (<p className='text-sm text-red-700'>{errors.address}</p>) : (null)}
              </div>
              <div>
                <label
                  htmlFor="educationDetails"
                  className="block text-sm font-medium "
                >
                  Education Details
                </label>
                <textarea
                  name="educationDetails"
                  placeholder="E.g., Degree, Institution, Year of Graduation"
                  value={values.educationDetails}
                  onChange={handleChange}
                  className="textarea textarea-bordered w-full p-2 rounded h-24 md:col-span-2"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                  }}
                ></textarea>
                {touched.educationDetails && errors.educationDetails ? (<p className='text-sm text-red-700'>{errors.educationDetails}</p>) : (null)}
              </div>
              <div>
                <label
                  htmlFor="keySkills"
                  className="block text-sm font-medium "
                >
                  key Interest
                </label>
                <input
                  type="text"
                  name="keySkills"
                  placeholder="E.g., Data Science, Marketing, Finance"
                  value={values.keySkills}
                  onChange={handleChange}
                  className="input input-bordered w-full p-2 rounded"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                  }}
                />
                {touched.keySkills && errors.keySkills ? (<p className='text-sm text-red-700'>{errors.keySkills}</p>) : (null)}
              </div>
              
              {/* <div>
                <label
                  htmlFor="roleApplyFor"
                  className="block text-sm font-medium"
                >
                  Role Apply For
                </label>
                <input
                  type="text"
                  name="roleApplyFor"
                  placeholder="eg. junior / senior data scientist"
                  value={values.roleApplyFor}
                  onChange={handleChange}
                  className="input input-bordered w-full p-2 rounded"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                  }}
                />
                {touched.roleApplyFor && errors.roleApplyFor ? (<p className='text-sm text-red-700'>{errors.roleApplyFor}</p>) : (null)}
              </div> */}
              

              {/* <div className="">
                <label className="block text-sm font-medium ">Work Mode *</label>
                <div className="flex flex-wrap p-2 border border-white rounded-md">
                  {['Remote', 'Hybrid', 'Office'].map((mode) => (
                    <div key={mode} className="flex items-center mr-4 mb-2">
                      <input
                        type="checkbox"
                        id={mode}
                        name="workMode"
                        value={mode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.workMode.includes(mode)}
                        className="mr-2"
                      />
                      <label htmlFor={mode} className="text-sm ">{mode}</label>
                    </div>
                  ))}
                </div>
                {touched.workMode && errors.workMode ? (
                  <p className="text-sm text-red-700">{errors.workMode}</p>
                ) : null}
              </div>

              <div>
                <label className="block text-sm font-medium">Work Category *</label>
                <div className="flex flex-wrap p-2 border border-white rounded-md ">
                  {['Internship', 'FullTime', 'PartTime'].map((category) => (
                    <div key={category} className="flex items-center mr-4 mb-2">
                      <input
                        type="checkbox"
                        id={category}
                        name="workCategory"
                        value={category}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.workCategory.includes(category)}
                        className="mr-2"
                      />
                      <label htmlFor={category} className="text-sm ">{category}</label>
                    </div>
                  ))}
                </div>
                {touched.workCategory && errors.workCategory ? (
                  <p className="text-sm text-red-700">{errors.workCategory}</p>
                ) : null}
              </div> */}

              {/* testing code end  */}
              <div>
                <label htmlFor="universityApplyFor" className="block text-sm font-medium">
                  University Applying For *
                </label>
                <input
                  type="text"
                  name="universityApplyFor"
                  placeholder="E.g., Stanford University, Oxford University"
                  value={values.universityApplyFor}
                  onChange={handleChange}
                  className="input input-bordered w-full p-2 rounded"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                  }}
                />
                {touched.universityApplyFor && errors.universityApplyFor ? (<p className='text-sm text-red-700'>{errors.universityApplyFor}</p>) : (null)}
              </div>
              <div>
                <label htmlFor="courseApplyingFor" className="block text-sm font-medium">
                  Course Applying For *
                </label>
                <input
                  type="text"
                  name="courseApplyingFor"
                  placeholder="E.g., MBA, Computer Science, Engineering"
                  value={values.courseApplyingFor}
                  onChange={handleChange}
                  className="input input-bordered w-full p-2 rounded"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                  }}
                />
                {touched.courseApplyingFor && errors.courseApplyingFor ? (<p className='text-sm text-red-700'>{errors.courseApplyingFor}</p>) : (null)}
              </div>


              {/*  */}
              <div>
                <label htmlFor="linkedinUrl" className="block text-sm font-medium">
                  LinkedIn URL
                </label>
                <input
                  type="text"
                  name="linkedinURL"
                  placeholder="www.linkedin.com/in/John-Smith256"
                  value={values.linkedinURL}
                  onChange={handleChange}
                  className="input input-bordered w-full p-2 rounded bg-transparent border-1"
                />
                {touched.linkedinURL && errors.linkedinURL && <p className='text-sm text-red-700'>{errors.linkedinURL}</p>}
              </div>
              {/*  */}

              {/* Include other form fields similarly */}
              <div>
                <label htmlFor="photo" className="block text-sm font-medium ">
                  Candidate Photo
                </label>
                <input
                  type="file"
                  name="photo"
                  id="photo"
                  accept="image/*"
                  // onChange={handleChange}
                  onChange={handlePhotoChange}
                  className="mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                  }}
                />
                {touched.photo && errors.photo ? (<p className='text-sm text-red-700'>{errors.photo}</p>) : (null)}
              </div>
              {/* resume original start */}
              {/* <div>
                <label htmlFor="resume" className="block text-sm font-medium ">
                  Resume only (.pdf) *
                </label>
                <input
                  type="file"
                  name="resume"
                  id="resume"
                  accept=".pdf"
                  onChange={handleChange}
                  className="mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                  }}
                />
                {touched.resume && errors.resume ? (<p className='text-sm text-red-700'>{errors.resume}</p>) : (null)}
              </div> */}
              {/* resume original end */}

              {/* <div>
                <label htmlFor="sop" className="block text-sm font-medium ">
                  Resume only (.pdf) *
                </label>
                <input
                  type="file"
                  name="resume"
                  id="resume"
                  accept=".pdf"
                  onChange={handleFileChange}
                  // onChange={(event) => {
                  //     // setFile("file",event.target.files[0])
                  //     const selectedFile = event.target.files[0];
                  //     setFieldValue(selectedFile);
                  //     console.log("2222222222222222222222222222222222222222222",event.target.files[0])
                  //   }}


                  className="mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                  }}
                />
                {touched.resume && errors.resume ? (<p className='text-sm text-red-700'>{errors.resume}</p>) : (null)}
              </div> */}
              <div>
                <label htmlFor="sop" className="block text-sm font-medium ">
                  SOP upload (.pdf) *
                </label>
                <input
                  type="file"
                  name="sop"
                  id="sop"
                  accept=".pdf"
                  placeholder="Upload your Statement of Purpose (SOP)"
                  onChange={handleFileChange}
                  // onChange={(event) => {
                  //     // setFile("file",event.target.files[0])
                  //     const selectedFile = event.target.files[0];
                  //     setFieldValue(selectedFile);
                  //     console.log("2222222222222222222222222222222222222222222",event.target.files[0])
                  //   }}


                  className="mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid #fff",
                  }}
                />
                {touched.sop && errors.sop ? (<p className='text-sm text-red-700'>{errors.sop}</p>) : (null)}
              </div>



              <div className="md:col-span-2 flex justify-end">
                <button
                  type="cancel"
                  className="w-full md:w-auto  hover:bg-blue-700 text-white font-medium py-2 px-4 mr-4 rounded"
                  style={{
                    backgroundColor: "transperant",
                    border: "1px solid #9900ff",
                    color: "#9900ff",
                  }}
                  // onClick={() => navigate("/CandidateDashboard")}
                  onClick={() => navigate("/")}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="w-full md:w-auto  hover:bg-blue-700  font-medium py-2 px-4 rounded"
                  style={{
                    backgroundColor: "#9900ff",
                    border: "1px solid #9900ff",
                    color: "#fff",
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default CandidateProfileReg;
